import { useEffect, useState } from 'react';
import IdaAnalyticsService from '../../../../services/ida.analytics.service';

const useFetchUserLogAnalytics = (onLoad = true, limit = 25) => {
  const [userLogs, setUserLogs] = useState([]);
  const [archive, setArchive] = useState('');
  const [dateRange, setDateRange] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchUserLogs = async (page = 1) => {
    try {
      setLoading(true);
      const data = await IdaAnalyticsService.getUserLogs({
        archiveId: archive?.archiveId,
        date: dateRange,
        userTablePagination: { page, limit },
      });
      if (page === 1) {
        setUserLogs(data);
      } else {
        setUserLogs((prevChats) => [...prevChats, ...data]);
      }
      const userPagination = {
        totalPages: 1,
        count: limit,
      };
      setPagination({
        currentPage: page,
        totalPages: userPagination.totalPages,
        count: userPagination.count,
      });
      setHasMore(page < userPagination.totalPages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchNextUserLogs = () => {
    if (!hasMore) return;
    if (pagination.currentPage < pagination.totalPages) {
      fetchUserLogs(pagination.currentPage + 1);
    }
  };

  useEffect(() => {
    if (onLoad) {
      archive?.archiveId &&
        Object.keys(dateRange).length > 0 &&
        fetchUserLogs(1);
    }
  }, [archive, dateRange]);

  return {
    userLogs,
    setUserLogs,
    setDateRange,
    setArchive,
    loading,
    pagination,
    setPagination,
    fetchUserLogs,
    fetchNextUserLogs,
    hasMore,
  };
};

export default useFetchUserLogAnalytics;
