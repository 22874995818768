import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import { overflowing } from '../../../utils/Utils';
import SimpleModalCreation from '../../modal/SimpleModalCreation';
import { FormGroup, Label } from 'reactstrap';
import { FormControl } from 'react-bootstrap';
import ArchiveService from '../../../services/archive.service';
import useHash from '../../../hooks/useHash';
import MaterialIcon from '../../commons/MaterialIcon';
import EmojiPickerDropdown from '../../commons/EmojiPickerDropdown';
const CreateKnowledgeModal = ({
  show,
  setShow,
  setSuccessMessage,
  knowledge = {},
  onUpdate,
}) => {
  const { updateHash } = useHash();

  const defaultKnowledgeObject = {
    title: '',
    description: '',
    icon: '',
    prompt: '',
    isPublic: false,
  };

  const [modalKnowledge, setModalKnowledge] = useState(defaultKnowledgeObject);
  const [saving, setSaving] = useState(false);

  const handleCreateKnowledge = async () => {
    try {
      setSaving(true);
      const isUpdating = !!knowledge?.archiveId;
      const data = isUpdating
        ? await ArchiveService.updateArchive(knowledge.archiveId, {
            icon: modalKnowledge?.icon,
            title: modalKnowledge.title,
            prompt: modalKnowledge.prompt,
            description: modalKnowledge.description,
          })
        : await ArchiveService.createArchive({
            ...modalKnowledge,
            icon: modalKnowledge?.icon,
          });
      if (isUpdating) {
        setShow(false);
        setSuccessMessage('Knowledge Updated');
        onUpdate({
          ...knowledge,
          icon: modalKnowledge.icon,
          title: modalKnowledge.title,
          prompt: modalKnowledge.prompt,
          description: modalKnowledge.description,
        });
      } else {
        const archiveId = data?.input?.archive?.archiveId;
        setShow(false);
        setSuccessMessage('Knowledge Created');
        updateHash(`#knowledge/${archiveId}`);
      }
      overflowing();
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (show) {
      setModalKnowledge({ ...knowledge });
    }
  }, [show, knowledge]);

  const onKeyEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleCreateKnowledge();
    }
  };

  return (
    <SimpleModalCreation
      modalTitle={
        knowledge?.archiveId ? 'Update Knowledge' : 'Create Knowledge'
      }
      open={show}
      saveButton={knowledge?.archiveId ? 'Update' : 'Continue'}
      isLoading={saving}
      buttonsDisabled={!modalKnowledge.title}
      deleteButton={null}
      handleSubmit={handleCreateKnowledge}
      bodyClassName="p-3"
      size="lg"
      onHandleCloseModal={() => {
        overflowing();
        setShow(false);
        setModalKnowledge(defaultKnowledgeObject);
      }}
    >
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2 align-items-center">
          <FormGroup className="flex-1">
            <Label>Title</Label>
            <FormControl
              type="text"
              name="title"
              onKeyDown={onKeyEnter}
              value={modalKnowledge.title}
              onChange={(e) => {
                setModalKnowledge({
                  ...modalKnowledge,
                  title: e.target.value,
                });
              }}
              placeholder="Knowledge title"
            />
          </FormGroup>

          <FormGroup className="d-flex flex-column gap-1 ml-2">
            <Label>Emoji</Label>
            <EmojiPickerDropdown
              setEmoji={(emoji) => {
                setModalKnowledge({
                  ...modalKnowledge,
                  icon: emoji.unified,
                });
              }}
            >
              <a className="cursor-pointer fs-3 icon-hover-bg">
                {modalKnowledge?.icon ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `&#x${modalKnowledge.icon};`,
                    }}
                  />
                ) : (
                  <div className="bg-gray-100 rounded-circle p-2 d-flex align-items-center justify-content-center">
                    <MaterialIcon
                      icon="add"
                      size="fs-3"
                      clazz="rounded-circle"
                    />
                  </div>
                )}
              </a>
            </EmojiPickerDropdown>
          </FormGroup>
        </div>

        <FormGroup>
          <Label>
            Description{' '}
            <span className="font-weight-normal text-muted-100">
              (optional)
            </span>
          </Label>
          <textarea
            className="form-control"
            placeholder="Knowledge description..."
            rows={3}
            name="description"
            value={modalKnowledge.description}
            onChange={(e) => {
              setModalKnowledge({
                ...modalKnowledge,
                description: e.target.value,
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            Custom Instructions{' '}
            <span className="font-weight-normal text-muted-100">
              (optional)
            </span>
          </Label>
          <p className="font-size-sm2 text-muted-100">
            Give instructions to the AI that affects every thread in this
            knowledge.
          </p>
          <textarea
            className="form-control"
            name="prompt"
            value={modalKnowledge.prompt}
            onChange={(e) => {
              setModalKnowledge({
                ...modalKnowledge,
                prompt: e.target.value,
              });
            }}
            placeholder="Provide instructions for AI to follow..."
            rows={5}
          />
        </FormGroup>
      </div>
    </SimpleModalCreation>
  );
};
const useCreateKnowledgeModal = (onUpdate) => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [knowledge, setKnowledge] = useState({});

  const CreateKnowledgeModalCallback = useCallback(() => {
    return (
      <>
        <AlertWrapper className="alert-position">
          {successMessage && (
            <Alert
              color="success"
              message={successMessage}
              setMessage={setSuccessMessage}
            />
          )}
          {errorMessage && (
            <Alert
              color="danger"
              message={errorMessage}
              setMessage={setErrorMessage}
            />
          )}
        </AlertWrapper>

        <CreateKnowledgeModal
          show={showModal}
          setShow={setShowModal}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          knowledge={knowledge}
          onUpdate={onUpdate}
        />
      </>
    );
  }, [
    showModal,
    setShowModal,
    successMessage,
    errorMessage,
    setSuccessMessage,
    setErrorMessage,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      setKnowledge,
      CreateKnowledgeModal: showModal
        ? CreateKnowledgeModalCallback
        : () => <div />,
    }),
    [setShowModal, setKnowledge, CreateKnowledgeModalCallback]
  );
};

export default useCreateKnowledgeModal;
