import React, { useEffect, useState } from 'react';
import { MetricCard } from '../../../components/chat/MetricCard';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import moment from 'moment';
import useFetchArchives from '../../../components/chat/hooks/useFetchArchives';
import Skeleton from 'react-loading-skeleton';
import useFetchOverviewDashboardAnalytics from './hooks/useFetchOverviewDashboardAnalytics';
import { numberWithCommas } from '../../../utils/Utils';
import NoDataFound from '../../../components/commons/NoDataFound';

const DataContainer = ({ loading, tenantsData }) => {
  const queriesData = [
    { rank: 1, text: 'How to integrate with the API?' },
    { rank: 2, text: 'What are the pricing options?' },
    { rank: 3, text: 'How to set up user permissions?' },
    { rank: 4, text: 'What file formats are supported?' },
    { rank: 5, text: 'How to upgrade my subscription?' },
    { rank: 6, text: 'How to troubleshoot connection issues?' },
    { rank: 7, text: 'What are the system requirements?' },
    { rank: 8, text: 'How to customize the interface?' },
    { rank: 9, text: 'What security features are available?' },
    { rank: 10, text: 'How to export my data?' },
  ];
  return (
    <div className="data-container gap-3">
      <div className="data-card border rounded">
        <div className="card-header">
          <h2 className="font-weight-semi-bold fs-6 mb-0 card-title d-flex align-items-center gap-1">
            Top Tenants by Usage
          </h2>
        </div>
        <div className="py-2 h-100 overflow-y-auto">
          {loading ? (
            <div className="p-3">
              <Skeleton height={10} count={3} className="mb-3" width="100%" />
            </div>
          ) : (
            <>
              {tenantsData.length > 0 ? (
                <ul className="ranked-list mb-0">
                  {tenantsData.map((tenant, index) => (
                    <li key={index}>
                      {loading ? (
                        <Skeleton height={10} width="100%" />
                      ) : (
                        <>
                          <div className="d-flex align-items-center w-100 justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="rank">{index + 1}</span>
                              <span className="list-item">
                                {tenant.tenantName}
                              </span>
                            </div>
                            <div>
                              {numberWithCommas(tenant.totalQuestions || '0')}
                            </div>
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <NoDataFound
                  icon="chart_data"
                  containerStyle="text-gray-search h-100 justify-content-center py-3"
                  titleStyle="font-normal font-size-sm2 text-gray-search"
                  title="No data"
                />
              )}
            </>
          )}
        </div>
      </div>

      <div className="data-card border disabled opacity-sm rounded">
        <div className="card-header">
          <h2 className="font-weight-semi-bold fs-6 mb-0 card-title d-flex align-items-center gap-1">
            Top Queries
          </h2>
        </div>
        <div className="py-2 h-100 overflow-y-auto">
          <ul className="ranked-list">
            {queriesData.map((query, index) => (
              <li key={index}>
                <span className="rank">{query.rank}</span>
                <span className="list-item">{query.text}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const Overview = () => {
  const [selectedTimeRange, setSelectedTimeRange] = useState({});
  const [selectedContentFilter, setSelectedContentFilter] = useState({});
  const { loadingPremiumArchives, premiumArchives, fetchPremiumArchives } =
    useFetchArchives(false, 50, {
      hasPlans: true,
    });
  const {
    overviewLogs,
    loading: loadingLogs,
    fetchOverviewLogs,
    setArchive,
    setDateRange,
  } = useFetchOverviewDashboardAnalytics();
  const now = moment();
  const timeRangeOptions = [
    {
      key: '7days',
      name: 'Last 7 Days',
      icon: 'calendar_month',
      start: now.clone().subtract(6, 'days').startOf('day'),
      end: now.clone().endOf('day'),
    },
    {
      key: '30days',
      name: 'Last 30 Days',
      icon: 'calendar_month',
      start: now.clone().subtract(29, 'days').startOf('day'),
      end: now.clone().endOf('day'),
    },
    {
      key: '90days',
      name: 'Last 90 Days',
      icon: 'calendar_month',
      start: now.clone().subtract(89, 'days').startOf('day'),
      end: now.clone().endOf('day'),
    },
  ];

  const metricData = [
    {
      iconName: 'Building',
      title: 'Companies (Tenants)',
      value: numberWithCommas(overviewLogs?.countOfSubscriptions || 0),
      class: 'companies',
    },
    {
      iconName: 'Users',
      title: 'Users',
      value: numberWithCommas(
        overviewLogs?.countOfSubscriptionAssignments || 0
      ),
      class: 'users',
    },
    {
      iconName: 'MessageCircleQuestion',
      title: 'Avg. Questions per Day',
      value: overviewLogs?.averageDailyQuestions
        ? numberWithCommas(Math.floor(overviewLogs?.averageDailyQuestions))
        : 0,
      class: 'questions',
    },
    {
      iconName: 'ThumbsUp',
      title: 'Positive Feedback',
      value: overviewLogs?.averagePositiveRatingPercentage
        ? `${overviewLogs?.averagePositiveRatingPercentage?.toFixed(0)}%`
        : '0%',
      class: 'feedback',
    },
  ];
  const tenantsData = overviewLogs?.topTenantsByUsage || [];

  const handleTimeRangeSelect = (e, option) => {
    e.preventDefault();
    setSelectedTimeRange(option);
    setDateRange({
      start: option.start,
      end: option.end,
    });
  };

  const handleContentFilterSelect = (e, option) => {
    e.preventDefault();
    setSelectedContentFilter(option);
    setArchive(option);
  };

  useEffect(() => {
    timeRangeOptions?.start &&
      selectedContentFilter?.archiveId &&
      fetchOverviewLogs();
  }, [selectedContentFilter, timeRangeOptions]);

  useEffect(() => {
    fetchPremiumArchives();
  }, []);

  return (
    <div className="p-3">
      <header className="d-flex mb-3 align-items-center justify-content-between">
        <h1>Ida Overview Dashboard</h1>
        <div className="d-flex align-items-center gap-2">
          <ButtonFilterDropdown
            buttonText="Select Time Range"
            options={timeRangeOptions}
            handleFilterSelect={handleTimeRangeSelect}
            filterOptionSelected={selectedTimeRange}
            icon="calendar_month"
            btnToggleStyle="btn-sm"
          />
          {loadingPremiumArchives ? (
            <Skeleton height={10} width={200} />
          ) : (
            <ButtonFilterDropdown
              buttonText="Select Content"
              options={premiumArchives}
              customKeys={['archiveId', 'title']}
              handleFilterSelect={handleContentFilterSelect}
              filterOptionSelected={selectedContentFilter}
              icon="filter_alt"
              btnToggleStyle="btn-sm min-width-200"
            />
          )}
          <ButtonIcon
            icon="download"
            classnames="btn-sm d-none"
            label="Export"
          />
        </div>
      </header>
      <div className="d-flex align-items-center gap-3 mb-3">
        {metricData.map((metric, index) => (
          <MetricCard
            key={index}
            iconName={metric.iconName}
            title={metric.title}
            value={metric.value}
            classType={metric.class}
            loading={loadingLogs}
          />
        ))}
      </div>
      <DataContainer loading={loadingLogs} tenantsData={tenantsData} />
    </div>
  );
};

export default Overview;
