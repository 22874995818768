import { useEffect, useState } from 'react';
import IdaAnalyticsService from '../../../../services/ida.analytics.service';

const useFetchOverviewDashboardAnalytics = (onLoad = true) => {
  const [overviewLogs, setOverviewLogs] = useState([]);
  const [archive, setArchive] = useState('');
  const [dateRange, setDateRange] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchOverviewLogs = async (page = 1) => {
    try {
      setLoading(true);
      const data = await IdaAnalyticsService.getOverviewDashboard({
        archiveId: archive?.archiveId,
        date: dateRange,
      });
      setOverviewLogs(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (onLoad) {
      archive?.archiveId &&
        Object.keys(dateRange).length > 0 &&
        fetchOverviewLogs(1);
    }
  }, [archive, dateRange]);

  return {
    overviewLogs,
    setOverviewLogs,
    setDateRange,
    setArchive,
    loading,
    fetchOverviewLogs,
  };
};

export default useFetchOverviewDashboardAnalytics;
