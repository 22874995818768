import BaseRequestService from './baseRequest.service';

import authHeader from './auth-header';

class IdaAnalyticsService extends BaseRequestService {
  getOverviewDashboard(data) {
    return this.post(`/analytics/idaOverviewDashboard`, data, {
      headers: authHeader(),
    });
  }

  getUserLogs(data) {
    return this.post(`/analytics/idaUserLog`, data, {
      headers: authHeader(),
    });
  }

  getConversationLogs(data) {
    return this.post(`/analytics/idaConversationLog`, data, {
      headers: authHeader(),
    });
  }

  getTopQuestions() {
    return this.get(`/chatQuestions/aggregate/topQuestions`, {
      headers: authHeader(),
    });
  }
}
export default new IdaAnalyticsService();
