import React, { useEffect, useState } from 'react';
import IdfTooltip from '../idfComponents/idfTooltip';
import { Dropdown } from 'react-bootstrap';
import ButtonIcon from '../commons/ButtonIcon';
import { ChatModels, PromptStyles, PromptStylesWithPrompt } from './constants';
import LucideIcon from '../commons/LucideIcon';
import AIModelsList from '../commons/AIModelsList';

const DefaultStyle = { key: PromptStyles.Normal, title: 'Normal' };
const DefaultModel = ChatModels[0];

const MenuOptions = {
  UseStyle: 1,
  ChooseModel: 2,
};
function PromptStyleDropdown({
  selectedStyle = DefaultStyle,
  selectedModel = DefaultModel,
  handlePromptStyle,
  handleSelectModel,
  readOnly,
}) {
  const [show, setShow] = useState(false);
  const [menuSelected, setMenuSelected] = useState('');

  const menuOptions = [
    {
      key: MenuOptions.UseStyle,
      icon: 'Feather',
      title: 'Use style',
      default: selectedStyle || DefaultStyle,
      onClick: () => setMenuSelected(MenuOptions.UseStyle),
    },
    {
      key: MenuOptions.ChooseModel,
      icon: 'Component',
      title: 'Select model',
      default: selectedModel || DefaultModel,
      onClick: () => setMenuSelected(MenuOptions.ChooseModel),
    },
  ];

  const MenuSelectedOptions = {
    [MenuOptions.UseStyle]: [
      {
        key: PromptStyles.Normal,
        title: 'Normal',
        prompt: PromptStylesWithPrompt.Normal,
        description: 'Balanced responses',
        onClick: (e, option) => handlePromptStyle(e, option),
      },
      {
        key: PromptStyles.Concise,
        title: 'Concise',
        prompt: PromptStylesWithPrompt.Concise,
        description: 'Straight to point',
        onClick: (e, option) => handlePromptStyle(e, option),
      },
      {
        key: PromptStyles.Formal,
        title: 'Formal',
        prompt: PromptStylesWithPrompt.Formal,
        description: 'Professional communication',
        onClick: (e, option) => handlePromptStyle(e, option),
      },
      {
        key: PromptStyles.Explanatory,
        icon: 'Feather',
        title: 'Explanatory',
        prompt: PromptStylesWithPrompt.Explanatory,
        description: 'Step-by-step breakdown',
        onClick: (e, option) => handlePromptStyle(e, option),
      },
    ],
    [MenuOptions.ChooseModel]: ChatModels.map((s) => ({
      ...s,
      static: true,
      onClick: (e, option) => handleSelectModel(e, option),
    })),
  };

  useEffect(() => {
    if (!show) {
      setMenuSelected('');
    }
  }, [show]);
  return (
    <Dropdown
      drop="down"
      show={show}
      className="files-dropdown"
      onToggle={!readOnly && setShow}
    >
      <Dropdown.Toggle
        className={`w-100 p-0 bg-hover-white border-0 text-gray-800`}
        variant="outline-link"
        id="dropdown-autoclose-true"
      >
        <span className="d-flex align-items-center gap-2">
          {!readOnly && (
            <IdfTooltip text="Setting options">
              <span>
                <ButtonIcon
                  color=""
                  classnames="btn-sm btn-icon p-1_2 font-size-sm size-30 d-flex align-items-center rounded-md border"
                  icon={show ? 'X' : 'Settings2'}
                  iconSize={18}
                  useLucidIcon
                  label=""
                />
              </span>
            </IdfTooltip>
          )}
          {selectedStyle?.key !== PromptStyles.Normal && (
            <div className="d-flex align-items-center transition-all hover-actions-none gap-1">
              <span className="d-flex cursor-pointer align-items-center border-1 border-primary-soft-100 rounded-md bg-primary-soft py-1 px-2 gap-1">
                <LucideIcon icon="Feather" size={14} clazz="text-gray-800" />
                <span className="fs-7">{selectedStyle?.title}</span>
                <a
                  className="cursor-pointer text-gray-800 action-items font-size-sm2"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setMenuSelected('');
                    handlePromptStyle({}, DefaultStyle);
                  }}
                >
                  <LucideIcon icon="X" size={14} />
                </a>
              </span>
            </div>
          )}
          {selectedModel?.key !== ChatModels[0].key && (
            <div className="d-flex align-items-center transition-all hover-actions-none gap-1">
              <span className="d-flex cursor-pointer align-items-center border-1 border-primary-soft-100 rounded-md bg-primary-soft py-1 px-2 gap-1">
                <LucideIcon icon="Component" size={14} clazz="text-gray-800" />
                <span className="fs-7">{selectedModel?.title}</span>
                <a
                  className="cursor-pointer text-gray-800 action-items font-size-sm2"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setMenuSelected('');
                    handleSelectModel({}, DefaultModel);
                  }}
                >
                  <LucideIcon icon="X" size={14} />
                </a>
              </span>
            </div>
          )}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        id="dropdown-menu-files"
        className={`border transition-all border-1 p-1_2 overflow-auto`}
        style={{ width: 300 }}
      >
        <div className="overflow-y-auto max-h-300">
          <div className={`menu-transition ${menuSelected ? 'show' : ''}`}>
            {MenuSelectedOptions[menuSelected] ? (
              <>
                <div
                  onClick={() => setMenuSelected('')}
                  className={`p-2 d-flex gap-1 flex-fill align-items-center text-muted cursor-pointer bg-hover-gray`}
                >
                  <LucideIcon icon="ArrowLeft" size={16} />
                  <span className={`font-size-sm2`}>Back</span>
                </div>
                <>
                  {menuSelected === MenuOptions.UseStyle ? (
                    <>
                      {MenuSelectedOptions[menuSelected]?.map(
                        (option, index) => (
                          <>
                            {option?.type === 'divider' ? (
                              <div
                                style={{ height: 1, width: '100' }}
                                className="border-top mt-1 pb-1"
                              />
                            ) : (
                              <div
                                key={index}
                                className="d-flex align-items-start w-100 justify-content-between"
                                onClick={(e) => {
                                  setShow(false);
                                  setMenuSelected('');
                                  option.onClick(e, option);
                                }}
                              >
                                <div
                                  className={`p-2 d-flex gap-1 flex-fill align-items-start text-muted cursor-pointer bg-hover-gray`}
                                >
                                  <div
                                    className={`font-size-sm2 d-flex flex-column text-left mb-0 ${
                                      selectedStyle?.key === option.key
                                        ? 'text-primary'
                                        : ''
                                    }`}
                                  >
                                    <p className="mb-0 fs-7 font-weight-medium">
                                      {option.title}
                                    </p>
                                    <p className="mb-0 fs-8 text-black-50 font-weight-normal">
                                      {option?.description}
                                    </p>
                                  </div>
                                  {selectedStyle?.key === option.key && (
                                    <div className="ml-auto d-flex align-items-start gap-1">
                                      <LucideIcon
                                        icon="Check"
                                        clazz="fw-bold text-primary"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        )
                      )}
                    </>
                  ) : (
                    <AIModelsList
                      selected={selectedModel}
                      handleSelected={handleSelectModel}
                      type="list"
                      listConfig={{
                        setShow,
                        setMenuSelected,
                        menuSelected: selectedModel,
                      }}
                    />
                  )}
                </>
              </>
            ) : null}
          </div>
          <div className={`menu-transition ${!menuSelected ? 'show' : ''}`}>
            {menuOptions?.map((option, index) => (
              <>
                {option?.type === 'divider' ? (
                  <div
                    style={{ height: 1, width: '100px' }}
                    className="border-top mt-1 pb-1"
                  />
                ) : (
                  <div
                    key={index}
                    className="d-flex align-items-center w-100 justify-content-between"
                    onClick={option.onClick}
                  >
                    <div
                      className={`p-2 d-flex gap-1 flex-fill align-items-center text-muted cursor-pointer bg-hover-gray`}
                    >
                      <LucideIcon icon={option.icon} size={16} />
                      <span
                        className={`font-size-sm2 ${
                          selectedStyle?.key === option.key
                            ? 'text-primary font-weight-semi-bold'
                            : ''
                        }`}
                      >
                        {option.title}
                      </span>
                      <div className="ml-auto d-flex align-items-center gap-1">
                        <span className="font-size-sm2 text-primary">
                          {option.default.title}
                        </span>
                        <LucideIcon
                          icon="ChevronRight"
                          clazz="fw-bold  text-gray-700"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default PromptStyleDropdown;
