import React from 'react';
import { useProfileContext } from '../../contexts/profileContext';
import LucideIcon from '../commons/LucideIcon';

const Greetings = ({
  margin = 'mb-4',
  centered = 'text-center justify-content-center mb-4',
  showHelpText = true,
  withIcon,
}) => {
  const { profileInfo } = useProfileContext();

  return (
    <>
      <h1
        className={`text-truncate fs-3 d-flex gap-2 align-items-center text-capitalize font-weight-semi-bold ${centered} mb-1`}
      >
        {withIcon && (
          <LucideIcon icon="Sparkles" size={28} clazz="text-primary" />
        )}
        {`Hello, ${profileInfo?.first_name || ''}!`}
      </h1>
      {showHelpText ? (
        <h1
          className={`fs-4 text-left font-weight-medium text-black-50 ${margin}`}
        >
          How can I help you today?
        </h1>
      ) : null}
    </>
  );
};

export default Greetings;
