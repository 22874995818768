import LucideIcon from '../commons/LucideIcon';
import Skeleton from 'react-loading-skeleton';

export const MetricCard = ({ iconName, title, value, classType, loading }) => {
  return (
    <div className={`metric-card border rounded flex-1 ${classType}`}>
      <div className={`metric-icon ${classType} fa-solid `}>
        <LucideIcon icon={iconName} size={22} color="#444" />
      </div>
      <div className="metric-content">
        <h5 className="text-gray-800">{title}</h5>
        <p className="font-size-225em mb-0 font-weight-bold">
          {loading ? <Skeleton height={10} width={50} /> : value}
        </p>
      </div>
    </div>
  );
};
