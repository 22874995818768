import React from 'react';
import { AvatarLogo } from './AvatarLogo';

const TenantLogo = ({
  tenant,
  imageStyle = 'ml-2',
  width = '90px',
  iconOrLogoKey = 'logo',
  height,
  minWidth,
}) => {
  return (
    <>
      <AvatarLogo
        name={tenant?.name}
        avatarId={tenant?.[iconOrLogoKey]}
        imageStyle={imageStyle}
        width={width}
        height={height}
        minWidth={minWidth}
      />
    </>
  );
};

export default TenantLogo;
