import React from 'react';
import ButtonFilterDropdown from './ButtonFilterDropdown';
import useFetchAIModels from '../chat/hooks/useFetchAIModels';
import Skeleton from 'react-loading-skeleton';
import StatusBadge from '../home/shared/StatusBadge';
import LucideIcon from './LucideIcon';

const ModelListItem = ({ option, listConfig, handleSelected }) => {
  return (
    <>
      {option?.type === 'divider' ? (
        <div
          style={{ height: 1, width: 100 }}
          className="border-top mt-1 pb-1"
        />
      ) : (
        <div
          className="d-flex align-items-start w-100 justify-content-between"
          onClick={(e) => {
            if (option.static) {
              return;
            }
            listConfig.setShow(false);
            listConfig.setMenuSelected('');
            handleSelected(e, option);
          }}
        >
          <div
            className={`p-2 d-flex gap-1 flex-fill align-items-start text-muted ${
              option.static
                ? 'cursor-default opacity-md'
                : 'cursor-pointer bg-hover-gray'
            }`}
          >
            <div
              className={`font-size-sm2 d-flex flex-column text-left mb-0 ${
                listConfig?.menuSelected?.key === option.key
                  ? 'text-primary'
                  : ''
              }`}
            >
              <p className="mb-0 fs-7 font-weight-medium">
                {option.title}
                {option.label ? (
                  <span className="ml-1">
                    <StatusBadge
                      showDot={false}
                      skipMap={true}
                      item={{
                        status: option.label !== 'default' ? 'Info' : 'Low',
                        priority: option.label !== 'default' ? 'Info' : 'Low',
                        statusText: option.label,
                      }}
                    />
                  </span>
                ) : null}
              </p>
              {option?.description && (
                <p className="mb-0 fs-8 text-black-50 font-weight-normal">
                  {option?.description}
                </p>
              )}
            </div>
            {listConfig?.menuSelected?.key === option.key && (
              <div className="ml-auto d-flex align-items-start gap-1">
                <LucideIcon icon="Check" clazz="fw-bold text-primary" />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

// type = list or dropdown
const AIModelsList = ({
  type = 'list',
  selected = {},
  handleSelected = () => {},
  listConfig = {},
}) => {
  const { loading, models, availableModels, reasoningModels } =
    useFetchAIModels();

  const SortByOrder = (a, b) => a.order - b.order;
  return (
    <>
      {loading ? (
        <>
          {type === 'list' ? (
            <div className="px-2">
              <Skeleton height={10} count={3} className="mb-3" width="100%" />
            </div>
          ) : (
            <div>
              <Skeleton height={10} width={200} />
            </div>
          )}
        </>
      ) : (
        <>
          {type === 'dropdown' ? (
            <ButtonFilterDropdown
              menuClass="rpt-history-dd-width max-h-300"
              buttonText="Select model"
              options={models}
              hideIcon={true}
              customKeys={['key', 'title', 'description']}
              filterOptionSelected={selected}
              handleFilterSelect={handleSelected}
            />
          ) : (
            <>
              {availableModels?.sort(SortByOrder).map((option) => (
                <ModelListItem
                  key={option.key}
                  listConfig={listConfig}
                  option={option}
                  handleSelected={handleSelected}
                />
              ))}
              <div className="px-2 py-1 gap-1 d-flex align-items-center">
                <span className="fs-10 text-gray-800 text-uppercase">
                  Reasoning
                </span>
                <div className="flex-1 border-top w-100" />
              </div>
              {reasoningModels?.sort(SortByOrder).map((option) => (
                <ModelListItem
                  key={option.key}
                  listConfig={listConfig}
                  option={option}
                  handleSelected={handleSelected}
                />
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AIModelsList;
