import React, { useState } from 'react';
import IdfTooltip from '../idfComponents/idfTooltip';
import { Dropdown } from 'react-bootstrap';
import ButtonIcon from '../commons/ButtonIcon';
import LucideIcon from '../commons/LucideIcon';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
import { maxFileLimit } from './constants';

function FilesAttachDropdown({
  files,
  handleAttachClick,
  triggerCameraClick,
  handleKnowledgeSelect = () => {},
  isHome,
}) {
  const [show, setShow] = useState(false);

  const options = [
    {
      icon: 'Paperclip',
      title: 'Upload a file',
      action: (e) => handleAttachClick(e),
    },
    {
      icon: 'Camera',
      title: 'Take a screenshot',
      action: (e) => triggerCameraClick(e),
    },
    ...(isHome
      ? [
          {
            type: 'divider',
          },
          {
            icon: 'SquareLibrary',
            title: 'Select knowledge',
            action: (e) => {
              setShow(false);
              handleKnowledgeSelect(e);
            },
          },
        ]
      : []),
  ];

  return (
    <Dropdown
      drop="down"
      show={show}
      className="files-dropdown"
      onToggle={setShow}
    >
      <Dropdown.Toggle
        className={`w-100 p-0 bg-hover-white border-0`}
        variant="outline-link"
        id="dropdown-autoclose-true"
      >
        <IdfTooltip text="Add knowledge">
          <ButtonIcon
            color=""
            classnames="btn-sm btn-icon p-1_2 size-30 font-size-sm d-flex align-items-center rounded-md border"
            icon={show ? 'X' : 'Plus'}
            iconSize={18}
            useLucidIcon
            label=""
          />
        </IdfTooltip>
      </Dropdown.Toggle>

      <Dropdown.Menu
        id="dropdown-menu-files"
        className={`border border-1 transition-all p-1_2 overflow-auto`}
        style={{ width: 250 }}
      >
        <div className="overflow-y-auto max-h-300">
          {options?.map((option, index) => (
            <>
              {option?.type === 'divider' ? (
                <div
                  style={{ height: 1, width: '100' }}
                  className="border-top mt-1 pb-1"
                />
              ) : (
                <div
                  key={index}
                  className="d-flex align-items-center w-100 justify-content-between"
                  onClick={files?.length >= maxFileLimit ? null : option.action}
                >
                  <div
                    className={`p-2 d-flex gap-1 flex-fill align-items-center text-muted ${
                      files?.length >= maxFileLimit
                        ? 'bg-gray-300'
                        : 'cursor-pointer bg-hover-gray'
                    }`}
                  >
                    <LucideIcon icon={option.icon} size={16} />

                    <TextOverflowTooltip
                      maxLength={30}
                      textStyle="font-size-sm"
                      text={option.title}
                    />
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default FilesAttachDropdown;
