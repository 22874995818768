import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { initialFilters } from './Contacts.constants';
import organizationService from '../../../services/organization.service';
import {
  OWNER,
  paginationDefault,
  COMPANY_CREATED,
  ADD_INSIGHT,
  INSIGHT_CREATED,
} from '../../../utils/constants';
import OrganizationForm from '../../../components/organizations/OrganizationForm';
import {
  changePaginationLimit,
  changePaginationPage,
  reducer,
  removeCustomFieldsFromActivityForm,
} from './utils';
import userService from '../../../services/user.service';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import routes from '../../../utils/routes.json';
import { orgDynamicFields } from '../../../components/organizations/organizationFormsFields';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';

import stringConstants from '../../../utils/stringConstants.json';
import LayoutHead from '../../../components/commons/LayoutHead';
import { sortingTable } from '../../../utils/sortingTable';
import {
  DATE_FORMAT_MONTH,
  RIGHT_PANEL_WIDTH,
  endOfLastWeekString,
  endOfWeekString,
  setDateFormat,
  splitAddress,
  startOfLastWeekString,
  startOfWeekString,
  TableAvatarGroupSize,
} from '../../../utils/Utils';
import FilterTabsButtonDropdown from '../../../components/commons/FilterTabsButtonDropdown';
import fieldService from '../../../services/field.service';
import { useForm } from 'react-hook-form';
import RightPanelModal from '../../../components/modal/RightPanelModal';
import { groupBy } from 'lodash';
import { usePagesContext } from '../../../contexts/pagesContext';
import { useProfileContext } from '../../../contexts/profileContext';
import IdfOwnersHeader from '../../../components/idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import useIsTenant from '../../../hooks/useIsTenant';
import { useModuleContext } from '../../../contexts/moduleContext';
import Avatar from '../../../components/Avatar';
import IdfTooltip from '../../../components/idfComponents/idfTooltip';
import TableFooterStats from '../../../components/TableFooterStats';
import DemoBankInsights from '../Organization/DemoBankInsights';
import TopicIcon from '../../../components/commons/TopicIcon';
import Table from '../../../components/NewGenericTable';
import Pagination from '../../../components/Pagination';
import DemoBankPortfolioAnalysis from '../Organization/DemoBankPortfolioAnalysis';
import DarkStatusBadge from '../../../components/commons/DarkStatusBadge';
import TooltipComponent from '../../../components/lesson/Tooltip';

const organizationConstants = stringConstants.deals.organizations;

const defaultFilter = {
  id: 6,
  type: 'filter',
  key: 'RecentlyModified',
  name: 'Recently Modified',
};

const ORGANIZATION_FILTER_OPTIONS_LIST = [
  { ...defaultFilter },
  { id: 5, type: 'filter', key: 'RecentlyCreated', name: 'Recently Created' },
  { id: 4, type: 'filter', key: 'AddedThisWeek', name: 'Added This Week' },
  { id: 3, type: 'filter', key: 'AddedLastWeek', name: 'Added Last Week' },
];

const Organizations = ({
  selectedOwnership,
  setSelectedOwnership,
  stats,
  isViewMore,
  setIsViewMore,
  setShowAdvancedFilters,
}) => {
  const organizationObj = {
    name: '',
  };
  const {
    register,
    handleSubmit,
    reset,
    getFieldState,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: organizationObj,
  });
  const { pageContext, setPageContext } = usePagesContext();
  const { moduleMap } = useModuleContext();
  const { profileInfo } = useProfileContext();

  const { isSynovus, isDemo } = useIsTenant();
  const [demoBankInsights, setDemoBankInsights] = useState(false);
  const [demoBankPortfolioAnalysis, setDemoBankPortfolioAnalysis] =
    useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [modal, setModal] = useState(false);
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [filtersItems, setFiltersItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);
  const [order, setOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters] = useReducer(reducer, initialFilters);
  const history = useHistory();
  const [showDeleteOrgModal, setShowDeleteOrgModal] = useState(false);
  const [dataInDB, setDataInDB] = useState(false);
  const [preOwners, setPreOwners] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterTabs, setFilterTabs] = useState('filters');
  const [isFieldsData, setIsFieldsData] = useState([]);
  const [isFieldsObj, setIsFieldsObj] = useState(organizationObj);
  const [customFields, setCustomFields] = useState([]);
  const [addButtonLabel, setButtonLabel] = useState(ADD_INSIGHT);
  const [selectedOwner, setSelectedOwner] = useState('');

  const [modified, setModified] = useState(
    pageContext?.OrganizationsPage?.isModified || 0
  );

  const getIsModified = () => {
    return pageContext?.OrganizationsPage?.isModified || 0;
  };
  const [filterOptionSelected, setFilterOptionSelected] = useState(
    pageContext?.OrganizationsPage?.filterOptionSelected
  );
  const defaultFilterSelected = {
    filter: {},
  };
  const [filterSelected, setFilterSelected] = useState(
    pageContext?.OrganizationsPage?.selectedFilter ?? defaultFilterSelected
  );
  useEffect(() => {
    // whenever filter applied from left side, remove existing filter
    if (selectedOwnership === 'My' && filterOptionSelected?.type === 'owner') {
      setFilterOptionSelected({});
      setFilterSelected(defaultFilterSelected);
      setSelectedOwner('');
      setPageContext({
        ...pageContext,
        OrganizationsPage: {
          ...pageContext.OrganizationsPage,
          filterOptionSelected: {},
          selectedFilter: defaultFilterSelected,
          isModified: getIsModified() + 1,
          user: '',
        },
      });
    } else if (
      selectedOwnership === 'All' &&
      pageContext?.OrganizationsPage?.user &&
      pageContext?.OrganizationsPage?.user !== '' &&
      filterOptionSelected?.type === 'owner'
    ) {
      onHandleFilterOrg(pageContext?.OrganizationsPage?.user);
    }

    setPaginationPage(paginationDefault);
    setModified((prevState) => prevState + 1);
  }, [selectedOwnership]);

  useEffect(() => {
    if (!isSynovus && moduleMap.organization)
      setButtonLabel(`Add ${moduleMap.organization.singular}`);
  }, [moduleMap.organization]);

  useEffect(() => {
    if (isViewMore) {
      const syntheticEvent = {
        preventDefault: () => {}, // Prevent default is required for the function
      };

      handleFilterSelect(syntheticEvent, defaultFilter);

      // Reset `isViewMore` to prevent repeated triggers
      setIsViewMore(false);
    }
  }, [isViewMore]);

  const handleFilterSelect = (e, status) => {
    setPaginationPage(paginationDefault);
    e.preventDefault();
    setOpenFilter(false);

    let newFilterSelected = {
      ...filterSelected,
    };
    if (status.key === 'AddedLastWeek') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: {
          startDate: startOfLastWeekString,
          endDate: endOfLastWeekString,
        },
      };
    } else if (status.key === 'AddedThisWeek') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: {
          startDate: startOfWeekString,
          endDate: endOfWeekString,
        },
      };
    } else if (status.key === 'RecentlyCreated') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: {},
      };
      setOrder(['date_entered', 'desc']);
    } else if (status.key === 'RecentlyModified') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: {},
      };
      setOrder(['lastActivityDate', 'desc']);
    }

    const hasFilters = Object.keys(newFilterSelected.filter);

    if (!hasFilters.length) delete newFilterSelected.filter;

    setFilterSelected(newFilterSelected);
    setFilterOptionSelected(status);
    setModified((prevState) => prevState + 1);
    setPageContext({
      ...pageContext,
      OrganizationsPage: {
        ...pageContext.OrganizationsPage,
        filterOptionSelected: status,
        selectedFilter: newFilterSelected,
        isModified: getIsModified() + 1,
      },
    });
  };

  async function onGetUsers() {
    const response = await userService
      .getUsers(
        {
          search: '',
          users: [],
          filters: '',
        },
        {
          limit: 3,
        }
      )
      .catch((err) => err);
    const { data } = response || {};
    const newFilterOptions = filtersItems.slice();
    newFilterOptions.push({
      id: newFilterOptions.length,
      label: OWNER,
      name: 'assigned_user_id',
      options: data?.users,
      type: 'search',
    });
    setFiltersItems(newFilterOptions);
  }

  const getOrganizations = async (count) => {
    if (Object.keys(filterSelected).length || modified > 0 || order?.length) {
      setTableLoading(true);

      let newFilterSelected = {
        ...filterSelected,
        filter: {
          ...filterSelected.filter,
        },
      };

      if (selectedOwnership === 'My') {
        newFilterSelected = {
          ...newFilterSelected,
          filter: {
            ...newFilterSelected.filter,
            assigned_user_id: profileInfo?.id,
            self: true,
          },
        };
      } else if (selectedOwnership === 'All') {
        newFilterSelected = {
          ...newFilterSelected,
          filter: {
            ...newFilterSelected.filter,
            assigned_user_id: filterSelected?.filter?.assigned_user_id || null,
          },
        };
      }

      let newOrder = order;
      if (filterOptionSelected?.key === 'RecentlyCreated') {
        newOrder = ['date_entered', 'desc'];
      }

      const organizations = await organizationService
        .getOrganizations(
          { ...newFilterSelected, order: newOrder },
          {
            page: paginationPage.page,
            limit: paginationPage.limit,
          }
        )
        .catch((err) => console.log(err));

      const { data } = organizations || {};

      setAllOrganizations(data?.data);
      setPagination(data?.pagination);

      setDataInDB(count ? Boolean(data?.pagination?.count) : false);
      setTableLoading(false);
      setModified(0);

      setSelectedData([]);
    }
  };

  useEffect(() => {
    if (modified > 0) {
      getOrganizations(true);
    }
  }, [modified, order]);

  useEffect(() => {
    (async () => {
      onGetUsers();
      if (pageContext?.RefreshCompanyList) getOrganizations(true);
      const getOwnerUserId = {
        ...isFieldsObj,
        assigned_user_id: profileInfo?.id,
        user: profileInfo,
      };
      setIsFieldsObj(getOwnerUserId);
    })();
  }, [pageContext]);

  const deleteOrganizations = async () => {
    const msg = isSynovus
      ? 'Insight Deleted'
      : `${moduleMap.organization.singular} Deleted.`;

    try {
      await organizationService.deleteOrganizations(selectedData);
      setSuccessMessage(msg);
      setModified(true);
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const openDeleteModal = () => {
    setShowDeleteOrgModal(true);
  };

  const onHandleFilterOrg = (item) => {
    const newFilterSelected = {
      ...filterSelected,
      filter: item && item.id ? { assigned_user_id: item.id } : filters,
    };

    const hasFilters = Object.keys(newFilterSelected.filter);
    if (!hasFilters.length) delete newFilterSelected.filter;

    // Perform setSelectedOwnership action when owner filter applied
    if (item?.id !== profileInfo?.id) {
      setSelectedOwnership('All');
    }

    setFilterSelected(newFilterSelected);
    const newFilterOptionSelected = {
      key: item.id,
      id: item.id,
      name: item?.id ? `${item?.first_name} ${item?.last_name}` : 'Filters',
      type: 'owner',
    };
    setFilterOptionSelected(newFilterOptionSelected);
    setOpenFilter(false);
    setModified((prevState) => prevState + 1);
    setSelectedOwner(item);
    setPageContext({
      ...pageContext,
      OrganizationsPage: {
        ...pageContext.OrganizationsPage,
        filterOptionSelected: newFilterOptionSelected,
        selectedFilter: newFilterSelected,
        isModified: getIsModified() + 1,
        user: item,
      },
    });
  };

  const groupBySection = (fieldsList) => {
    setIsFieldsData(groupBy(fieldsList, 'section'));
  };

  const currentView = 'organization';
  const getFields = async () => {
    setIsLoading(true);
    const { data } = await fieldService.getFields(currentView, {
      preferred: true,
    });
    groupBySection(data);
    setIsLoading(false);
  };

  const toggle = () => {
    setModal(!modal);
    reset(organizationObj);
    setIsFieldsObj({
      assigned_user_id: isFieldsObj.assigned_user_id,
      user: isFieldsObj.user,
      ...organizationObj,
    });
    getFields();
    setCustomFields([]);
  };

  const onHandleSubmit = async () => {
    setLoading(true);
    // set US as country for now
    isFieldsObj.address_country = 'USA';

    // here splitting address back to what API needs
    isFieldsObj.address_street = isFieldsObj?.address_full
      ? splitAddress(isFieldsObj.address_full)?.address
      : '';
    const updateFields = removeCustomFieldsFromActivityForm(
      isFieldsObj,
      customFields
    );
    const newContact = await organizationService
      .createOrganization(updateFields)
      .catch((err) => console.log(err));

    if (newContact) {
      await Promise.all(
        customFields?.map(async (item) => {
          if (item?.value !== '')
            await new Promise((resolve) => {
              organizationService
                .updateCustomField(newContact?.data?.id, item)
                .then(resolve);
            });
        }),
        preOwners?.map(async (item) => {
          await new Promise((resolve) => {
            organizationService
              .addOwner(newContact?.data?.id, item.user_id)
              .then(resolve);
          });
        })
      );
      getOrganizations(true);
      const dataUpdate = {
        ...isFieldsObj,
        assigned_user_id: profileInfo?.id,
      };
      setIsFieldsObj(dataUpdate);
      setPreOwners([]);
      setSuccessMessage(
        isSynovus
          ? INSIGHT_CREATED
          : COMPANY_CREATED.replace(/Company/g, moduleMap.organization.singular)
      );

      toggle();
    }

    setLoading(false);
  };

  const onClose = () => {
    setModal(false);
    reset(organizationObj);
    setPreOwners([]);
    setIsFieldsObj({
      assigned_user_id: isFieldsObj.assigned_user_id,
      user: isFieldsObj.user,
      ...organizationObj,
    });
    setCustomFields([]);
  };

  const sortTable = ({ name }) => {
    setModified((prevState) => prevState + 1);
    sortingTable({ name, order, setOrder });
    setFilterOptionSelected({});
    setFilterSelected(defaultFilterSelected);
  };

  const handleRowClick = (event) => {
    const rowData = event.data;
    history.push(
      isSynovus
        ? `${routes.insightsCompanies}/${rowData.id}/organization/profile`
        : `${routes.companies}/${rowData.id}/organization/profile`
    );
  };

  const handleClearSelection = () => {
    setSelectedData([]);
  };

  const isMultiple = selectedData?.length > 1;

  const heading = isSynovus
    ? isMultiple
      ? 'Delete Insights'
      : 'Delete Insight'
    : `Delete ${moduleMap.organization[isMultiple ? 'plural' : 'singular']}`;

  const description = isSynovus
    ? isMultiple
      ? 'Are you sure you want to delete all these Insights?'
      : 'Are you sure you want to delete this Insight?'
    : `Are you sure you want to delete ${isMultiple ? 'all these' : 'this'} ${
        moduleMap.organization[isMultiple ? 'plural' : 'singular']
      }?`;

  const CompanyLogoRenderer = (row) => {
    const organization = row.data;
    return (
      <Link
        to={
          isSynovus
            ? `${routes.insightsCompanies}/${organization.id}/organization/profile`
            : `${routes.companies}/${organization.id}/organization/profile`
        }
        className="text-black text-truncate fw-bold d-flex align-items-center gap-2"
      >
        <Avatar
          classModifiers="bg-primary-soft text-primary min-w-25"
          sizeIcon="font-size-lg"
          defaultSize="xs"
          user={organization}
          type="organization"
        />
        <p className="font-weight-medium text-truncate fs-7_1 mb-0">
          {organization.name}
        </p>
      </Link>
    );
  };

  const LocationRenderer = (row) => {
    const organization = row.data;
    return (
      <>
        {organization.address_city || organization.address_state
          ? `            
          ${organization.address_city ? organization.address_city + ', ' : ''} 
          ${organization.address_state ? organization.address_state : ''}
        `
          : '--'}
      </>
    );
  };

  const NaicsRenderer = (row) => {
    const organization = row.data;
    return (
      <>
        {organization.industry
          ? `${organization.naics_code ? `(${organization.naics_code})` : ''} ${
              organization.industry
            }`
          : '--'}
      </>
    );
  };

  const AssignedUserRenderer = (row) => {
    const organization = row.data;
    return (
      <>
        {organization?.assigned_user ? (
          <p className="d-flex align-items-center mb-0 gap-1">
            <IdfTooltip
              text={`${organization?.assigned_user?.first_name} ${organization?.assigned_user?.last_name}`}
            >
              <Avatar user={organization?.assigned_user} defaultSize="xs" />
            </IdfTooltip>
            <span className="text-truncate max-w-120">
              {`${organization?.assigned_user?.first_name} ${organization?.assigned_user?.last_name}`}
            </span>{' '}
          </p>
        ) : (
          '--'
        )}
      </>
    );
  };

  const OwnersRenderer = (row) => {
    const organization = row.data;
    return (
      <div className="position-relative z-index-99 h-100 d-flex align-items-center">
        <IdfOwnersHeader
          mainOwner={organization.assigned_user}
          service={organizationService}
          serviceId={null}
          headerType="organization"
          {...TableAvatarGroupSize}
          isClickable={false}
          onClick={(e) => {
            e?.stopPropagation();
            e?.preventDefault();
          }}
          listOwners={organization.owners}
          defaultSize="xs"
          isprincipalowner={false}
          small
          prevalue="true"
        />
      </div>
    );
  };

  const LastModifiedRenderer = (row) => {
    const organization = row.data;
    return (
      <>
        {organization?.date_modified ? (
          <p className="d-flex align-items-center mb-0 gap-1">
            <span>
              {setDateFormat(organization?.date_modified, DATE_FORMAT_MONTH)}
            </span>
          </p>
        ) : (
          '--'
        )}
      </>
    );
  };

  const Tiers = {
    Strategic: 'Success',
    Core: 'Not Started',
    Transactional: 'Info',
  };
  const Labels = {
    'Integrated Payables': 'green',
    RTP: 'red',
    'Positive Pay': 'blue',
    'Commercial Card': 'gray',
    ACH: 'yellow',
    Checks: 'magenta',
    Lockbox: 'teal',
    Merchant: 'indigo',
  };

  const getRandomTier = () =>
    Object.keys(Tiers)[Math.floor(Math.random() * Object.keys(Tiers).length)];
  const getRandomLabels = () => {
    const shuffled = [...Object.keys(Labels)].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, Math.floor(Math.random() * 2) + 2);
  };

  const TagsRenderer = () => {
    const randomLabels = getRandomLabels();
    return (
      <div className="d-flex align-items-center h-100 gap-1">
        {randomLabels?.map((label) => (
          <DarkStatusBadge
            key={label}
            item={{
              color: Labels[label],
              text: label,
            }}
          />
        ))}
      </div>
    );
  };

  const CustomerTierRenderer = () => {
    const randomTier = getRandomTier();
    return (
      <>
        <div className="d-flex align-items-center h-100">{randomTier}</div>
      </>
    );
  };

  const columnDefs = [
    {
      field: 'name',
      orderBy: 'name',
      headerName: 'Company Name',
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: CompanyLogoRenderer,
    },
    {
      field: 'address',
      orderBy: 'address_city',
      headerName: 'Location',
      cellRenderer: LocationRenderer,
    },
    {
      field: 'industry',
      orderBy: '',
      headerName: 'NAICS Code',
      cellRenderer: NaicsRenderer,
    },
    {
      field: 'assigned_user',
      orderBy: 'assigned_user',
      headerName: 'Primary Contact',
      cellRenderer: AssignedUserRenderer,
    },
    {
      field: 'owners',
      orderBy: '',
      headerName: 'Owners',
      cellRenderer: OwnersRenderer,
    },
    {
      field: 'date_modified',
      orderBy: 'date_modified',
      headerName: 'Last Modified',
      cellRenderer: LastModifiedRenderer,
    },
    ...(isDemo
      ? [
          {
            field: 'tags',
            orderBy: '',
            headerName: 'Tags',
            cellRenderer: TagsRenderer,
          },
          {
            field: 'customer_tier',
            orderBy: '',
            headerName: 'Customer Tier',
            cellRenderer: CustomerTierRenderer,
          },
        ]
      : []),
  ];

  return (
    <div className="bg-white h-100">
      <div className="d-flex align-items-center justify-content-between positon-relative mb-2 pt-2 px-3">
        <FilterTabsButtonDropdown
          options={ORGANIZATION_FILTER_OPTIONS_LIST}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          filterOptionSelected={filterOptionSelected}
          filterSelected={filterSelected}
          filterTabs={filterTabs}
          handleFilterSelect={handleFilterSelect}
          onHandleFilterOwner={onHandleFilterOrg}
          setFilterOptionSelected={setFilterOptionSelected}
          setFilterSelected={setFilterSelected}
          setFilterTabs={setFilterTabs}
          setModified={setModified}
          extraClasses={'dropdown-left'}
          selectedOwner={selectedOwner}
          setSelectedOwner={setSelectedOwner}
          module="organization"
          setShowAdvancedFilters={setShowAdvancedFilters}
        />

        {moduleMap.organization && (
          <LayoutHead
            id="add-company-button"
            onHandleCreate={toggle}
            buttonLabel={addButtonLabel}
            selectedData={selectedData}
            onDelete={openDeleteModal}
            alignTop="my-0"
            dataInDB={dataInDB}
            permission={{
              collection: 'organizations',
              action: 'create',
            }}
            onClear={handleClearSelection}
          />
        )}

        {isDemo && (
          <div
            className="right-sidebar insights-bar border-left"
            style={{ zIndex: 999 }}
          >
            <div className="splitted-content-mini splitted-content-mini-right splitted-content-bordered pb-3 pt-4 sticky-top z-index-2 w-auto">
              <ul className="nav nav-compact-icon list-unstyled-py-3 nav-compact-icon-circle justify-content-center">
                <li className="d-flex flex-column gap-2 pb-0">
                  <DemoBankInsights
                    showModal={demoBankInsights}
                    setShowModal={setDemoBankInsights}
                  >
                    <div
                      className="nav-item scroll-item mb-3"
                      onClick={() => setDemoBankInsights(!demoBankInsights)}
                    >
                      <TooltipComponent title="Insights">
                        <div className="nav-icon insights cursor-pointer">
                          <TopicIcon
                            icon="search_insights"
                            iconBg="insights"
                            filled={true}
                            iconSize="fs-5"
                          />
                        </div>
                      </TooltipComponent>
                    </div>
                  </DemoBankInsights>
                </li>
                <li className="d-flex flex-column gap-2 pb-0">
                  <DemoBankPortfolioAnalysis
                    showModal={demoBankPortfolioAnalysis}
                    setShowModal={setDemoBankPortfolioAnalysis}
                  >
                    <div
                      className="nav-item scroll-item mb-3"
                      onClick={() =>
                        setDemoBankPortfolioAnalysis(!demoBankPortfolioAnalysis)
                      }
                    >
                      <TooltipComponent title="Portfolio">
                        <div className="nav-icon treasury forced cursor-pointer">
                          <TopicIcon
                            icon="trip"
                            iconBg="treasury"
                            filled={true}
                            iconSize="fs-5"
                          />
                        </div>
                      </TooltipComponent>
                    </div>
                  </DemoBankPortfolioAnalysis>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>

      <DeleteConfirmationModal
        showModal={showDeleteOrgModal}
        setShowModal={setShowDeleteOrgModal}
        handleCloseModal={handleClearSelection}
        event={deleteOrganizations}
        heading={heading}
        description={description}
        extraBody={
          <p className="mt-2 mb-0">{organizationConstants.delete.confirm}</p>
        }
      />

      <Card className="card-0 h-100 mb-0">
        <Card.Body className="p-0 h-100">
          <div className="table-responsive-md d-flex flex-column datatable-custom-2">
            <div className="">
              <div
                id="datatable_wrapper"
                className="dataTables_wrapper flex-1 h-100 no-footer"
              >
                <Table
                  checkbox
                  stickyColumn
                  tableId="organization"
                  columns={columnDefs}
                  data={allOrganizations}
                  showLoading={tableLoading}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  paginationInfo={{}}
                  title={`${moduleMap.organization.singular}`}
                  dataInDB={dataInDB}
                  emptyDataText={`No records in this view.`}
                  toggle={toggle}
                  sortingTable={sortTable}
                  sortingOrder={order}
                  handleRowClick={handleRowClick}
                  permission={{
                    collection: 'organizations',
                    action: 'create',
                  }}
                  stickyFooter={true}
                  stickyClass="accounts-table-container"
                  tableLoading={tableLoading}
                />
              </div>
            </div>

            <div className="border-top bg-gray-table-head">
              <div className="d-flex align-items-center py-1">
                <ul className="px-3 mb-0 flex-fill">
                  <TableFooterStats stats={stats} loading={loading} />
                </ul>
                <div className="pt-1 ml-auto">
                  <Pagination
                    showPerPage
                    paginationInfo={pagination}
                    onPageChange={(newPage) => {
                      changePaginationPage(newPage, setPaginationPage);
                      setModified((prevState) => prevState + 1);
                    }}
                    onLimitChange={(perPage) => {
                      changePaginationLimit(perPage, setPaginationPage);
                      setModified((prevState) => prevState + 1);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <RightPanelModal
        showModal={modal}
        setShowModal={() => onClose()}
        showOverlay={true}
        containerBgColor={'pb-0'}
        containerWidth={RIGHT_PANEL_WIDTH}
        containerPosition={'position-fixed'}
        headerBgColor="bg-gray-5"
        Title={
          <div className="d-flex py-2 align-items-center">
            {moduleMap.organization && (
              <h3 className="mb-0">
                {isSynovus
                  ? ADD_INSIGHT
                  : `Add ${moduleMap.organization.singular}`}
              </h3>
            )}
          </div>
        }
      >
        <OrganizationForm
          setIsFieldsObj={setIsFieldsObj}
          isFieldsObj={isFieldsObj}
          checkFieldsType={orgDynamicFields}
          fields={isFieldsData}
          refresh={() => getOrganizations(true)}
          me={profileInfo}
          onClose={onClose}
          isLoading={isLoading}
          loading={loading}
          onHandleSubmit={onHandleSubmit}
          handleSubmit={handleSubmit}
          register={register}
          customDataFields={customFields}
          setCustomDataFields={setCustomFields}
          setValue={setValue}
          getFieldState={getFieldState}
          control={control}
          errors={errors}
          labelType="organization"
          isprincipalowner="true"
          service={organizationService}
          prevalue="true"
          preowners={preOwners}
          setPreOwners={setPreOwners}
          organizationObj={organizationObj}
          fromNavBar
        />
      </RightPanelModal>

      <AlertWrapper>
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
    </div>
  );
};

export default Organizations;
