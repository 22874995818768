import ReportInfo from './tabs/ReportInfo';
import ReportDetails from './tabs/ReportDetails';
import WorkingCapital from './tabs/WorkingCapital';
import Widgets from './tabs/Widgets';
import React from 'react';
import moment from 'moment/moment';
import FundsFlow from './tabs/FundsFlow';
import { getRandomUUID } from '../../../utils/Utils';

export const ProductServicesList = [
  'Account Reconcilement Plan',
  'Account Analysis',
  'Automated Clearing House',
  'Business Deposit Capture',
  'Business Sweep',
  'Cash Letter',
  'Cash Vault',
  'Checking',
  'Commercial Card',
  'Consolidated Accounting Services',
  'Controlled Collateral Accounts',
  'Controlled Disbursement',
  'Correspondent Banking Services',
  'Courier Service',
  'Deposit Reconcilement',
  'Electronic Bill Payment',
  'Electronic Data Interchange',
  'Healthcare Receivable Automation',
  'Image Services',
  'Information Reporting and Payments',
  'Integrated Cash Position Manager',
  'Integrated Payables Services',
  'Integrated Receivables',
  'International',
  'Loan Management',
  'Lockbox',
  'Miscellaneous',
  'Payable through Drafts',
  'Real Time Payments',
  'Returned Items',
  'Target Balance Account',
  'Tax Payment Services',
  'Political Action Committee Services',
  'Treasury File Reporting',
  'Treasury Information Reporting',
  'Treasury Payments',
  'Treasury Packages',
  'Wire Transfers',
  'Flat Fee',
  'Intellect',
  'Mid Cycle Payments',
  'Inactive Services',
  'Intrafi Cash Transfer',
];
export const FraudServicesList = [
  'Positive Pay',
  'ACH Positive Pay',
  'Payee Match',
];
export const AccountTypesList = [
  { value: 'businessSavings', title: 'Business Savings' },
  { value: 'localBanks', title: 'Local Banks' },
  { value: 'interBankAccounts', title: 'Inter Bank Accounts' },
  { value: 'commercialMoneyMarket', title: 'Commercial Money Market' },
  { value: 'controlledDisbursement', title: 'Controlled Disbursement' },
  {
    value: 'commercialInterestChecking',
    title: 'Commercial Checking',
  },
  { value: 'officialBankChecks', title: 'Official Bank Checks' },
  {
    value: 'executiveSignatureChecking',
    title: 'Executive Signature Checking',
  },
  { value: 'execSignatureMoneyMarket', title: 'Exec Signature Money Market' },
  {
    value: 'executiveSignatureInterestChecking',
    title: 'Executive Signature Interest Checking',
  },
  { value: 'smallBusinessChecking', title: 'Small Business Checking' },
  {
    value: 'publicFundsInterestChecking',
    title: 'Public Funds Interest Checking',
  },
  {
    value: 'premiumBusinessInterestChecking',
    title: 'Premium Business Interest Checking',
  },
  { value: 'foreignBanks', title: 'Foreign Banks' },
  {
    value: 'smallBusinessInterestChecking',
    title: 'Small Business Interest Checking',
  },
  { value: 'businessMoneyMarket', title: 'Business Money Market' },
  { value: 'commercialChecking', title: 'Commercial Checking' },
  {
    value: 'premiumBusinessMoneyMarket',
    title: 'Premium Business Money Market',
  },
  {
    value: 'lawyersTrustInterestChecking',
    title: 'Lawyers Trust Interest Checking',
  },
  { value: 'basicBusinessChecking', title: 'Basic Business Checking' },
];

export const generateOneYearBalanceRecords = (startDate, keyAndIcon) => {
  const records = [];
  let currentDate = startDate ? moment(startDate) : moment();

  for (let i = 0; i < 12; i++) {
    const record = {
      id: getRandomUUID(),
      month: currentDate.format('MMMM YYYY'),
      year: currentDate.format('YYYY'),
      fullMonth: currentDate.format('MMMM'),
      value: 0,
      color: '65%',
    };
    if (keyAndIcon) {
      record.key = currentDate.format('YYYY-MM');
      record.name = record.month;
      record.icon = 'calendar_month';
      record.start = currentDate.startOf('month').toISOString();
      record.end = currentDate.endOf('month').toISOString();
    }
    records.push(record);
    currentDate = currentDate.subtract(1, 'month');
  }

  return records;
};
export const AccountStructureTabMap = {
  ReportInfo: {
    id: 1,
    key: 'ReportInfo',
    name: 'Report Info',
  },
  ReportDetails: {
    id: 2,
    key: 'ReportDetails',
    name: 'Report Details',
  },
  FundsFlow: {
    id: 3,
    key: 'FundsFlow',
    name: 'Funds Flow',
  },
  WorkingCapital: {
    id: 4,
    key: 'WorkingCapital',
    name: 'Working Capital',
  },
  Widgets: {
    id: 5,
    key: 'Widgets',
    name: 'Widgets',
  },
};

export const AccountStructureTabs = [
  {
    ...AccountStructureTabMap.ReportInfo,
    component: (
      report,
      setReport,
      errorFields,
      setErrorFields,
      organization
    ) => {
      return (
        <ReportInfo
          organization={organization}
          errorFields={errorFields}
          setErrorFields={setErrorFields}
        />
      );
    },
    isActive: true,
  },
  {
    ...AccountStructureTabMap.ReportDetails,
    component: (
      report,
      setReport,
      errorFields,
      setErrorFields,
      organization
    ) => {
      return (
        <ReportDetails
          errorFields={errorFields}
          setErrorFields={setErrorFields}
          organization={organization}
        />
      );
    },
    isActive: false,
  },
  {
    ...AccountStructureTabMap.FundsFlow,
    component: (
      report,
      setReport,
      errorFields,
      setErrorFields,
      organization
    ) => {
      return (
        <FundsFlow
          organization={organization}
          errorFields={errorFields}
          setErrorFields={setErrorFields}
        />
      );
    },
    isActive: false,
  },
  {
    ...AccountStructureTabMap.WorkingCapital,
    component: (
      report,
      setReport,
      errorFields,
      setErrorFields,
      organization
    ) => {
      return (
        <WorkingCapital
          report={report}
          setReport={setReport}
          errorFields={errorFields}
          setErrorFields={setErrorFields}
          organization={organization}
        />
      );
    },
    isActive: false,
  },
  {
    ...AccountStructureTabMap.Widgets,
    component: (
      report,
      setReport,
      errorFields,
      setErrorFields,
      organization
    ) => {
      return (
        <Widgets
          report={report}
          setReport={setReport}
          errorFields={errorFields}
          setErrorFields={setErrorFields}
          organization={organization}
        />
      );
    },
    isActive: false,
  },
];

export const getAccountType = (accountType) => {
  const foundType = AccountTypesList.find((tb) => tb.value === accountType);
  return foundType || { title: accountType };
};

export const AccountSchematicReportSections = {
  FundsFlow: 'Funds Flow',
  AccountStructure: 'Account Structure',
  BalanceTrends: 'Balance Trends',
  BalanceTrendsFraud: 'Balance Trends Fraud',
  WCDPO: 'Working Capital: Days Payable Outstanding',
  AccountsPayable: 'Accounts Payable',
  WCDSO: 'Working Capital: Days Sales Outstanding',
  AccountsReceivable: 'Accounts Receivables',
  AccountsReceivableAutomating: 'Automating Accounts Receivables',
  SignersUsersContacts: 'Authorized Signers',
  AuthorizedUsersContacts: 'Authorized Users',
  BankContacts: 'Bank Contacts',
  Disclaimer: 'Disclaimer',
};

export const AccountSchematicReportSectionsWidgets = {
  [AccountSchematicReportSections.AccountStructure]: {
    isActive: true,
  },
  [AccountSchematicReportSections.BalanceTrends]: {
    isActive: true,
  },
  [AccountSchematicReportSections.BalanceTrendsFraud]: {
    isActive: true,
  },
  [AccountSchematicReportSections.WCDPO]: {
    isActive: true,
  },
  [AccountSchematicReportSections.AccountsPayable]: {
    isActive: true,
  },
  [AccountSchematicReportSections.WCDSO]: {
    isActive: true,
  },
  [AccountSchematicReportSections.AccountsReceivable]: {
    isActive: true,
  },
  [AccountSchematicReportSections.AccountsReceivableAutomating]: {
    isActive: true,
  },
};

export const isAccountStructureTabFormValidated = (tabType, tabData) => {
  if (tabType === AccountStructureTabMap.ReportInfo.id) {
    return {
      validated: Boolean(
        tabData.companyName && tabData.valueNaicsSic && tabData.reportDate
      ),
      message: 'Please enter input in required fields.',
    };
  } else if (tabType === AccountStructureTabMap.ReportDetails.id) {
    return {
      validated: tabData.accounts.length > 0,
      message: 'Please add at least one account.',
    };
  }
  return {
    validated: true,
    message: '',
  };
};

export const getFraudServicesConfig = (account) => {
  const fraudServices = account?.fraudServices?.length
    ? account?.fraudServices?.split(', ')
    : [];
  let config = {
    color: '--alert-color-green-text',
    bg: '--alert-color-green-text',
    bgLite: '--login-alert-green-bg',
    icon: 'lock',
    text: 'This account is protected with fraud services.',
  };
  if (!fraudServices || fraudServices.length === 0) {
    config = {
      color: '--alert-color-red-text',
      bg: '--alert-color-red-text',
      icon: 'lock_open',
      bgLite: '--alert-color-red-bg',
      text: 'This account has no fraud protection services.',
    };
  } else if (fraudServices.length !== 3) {
    config = {
      color: '--alert-color-yellow-text',
      bg: '--alert-color-yellow-text',
      icon: 'lock',
      bgLite: '--alert-color-yellow-bg',
      text: 'This account has some protection with fraud services.',
    };
  }
  return config;
};

export const AccountTypes = {
  Operating: {
    id: 1,
    name: 'Operating Account',
    key: 'Primary',
    short: 'P',
    color: 'bg-blue text-white',
    foreColor: 'text-white',
  },
  SubAccount: {
    id: 2,
    name: 'Sub-Account',
    key: 'SubAccount',
    short: 'S',
    color: 'bg-purple text-white',
    foreColor: 'text-white',
  },
  Standalone: {
    id: 3,
    name: 'Standalone Account',
    short: 'S',
    key: 'Standalone',
    color: 'bg-gray-300 text-black',
    foreColor: 'text-black',
  },
  NonAnalyzed: {
    id: 4,
    key: 'NonAnalyzed',
    name: 'Non-Analyzed Account',
    short: 'N',
    color: 'bg-warning text-black',
    foreColor: 'text-black',
  },
};

export const getOperatingAccountType = (account) => {
  const isOldReport =
    Object.hasOwn(account, 'operatingAccount') ||
    Object.hasOwn(account, 'subAccountName');
  let operatingAccountType = account?.operatingAccountType || {};
  // if its an old report with operatingAccount/subAccount fields then
  // port that logic into new .operatingAccountType dropdown and select the related type
  if (isOldReport && !Object.keys(operatingAccountType).length) {
    if (account.operatingAccount === true) {
      operatingAccountType = AccountTypes.Operating;
    } else if (account.subAccountName && !account.operatingAccount) {
      operatingAccountType = AccountTypes.SubAccount;
    } else if (!account.subAccountName && !account.operatingAccount) {
      operatingAccountType = AccountTypes.Standalone;
    }
  }
  return operatingAccountType;
};

export const FilterNotPrimary = (item) => {
  return item.operatingAccountType.key !== AccountTypes.Operating.key;
};

export const cleanAccountName = (account) => {
  const name = account.accountName;
  const accountNumber = account.accountNumber;

  if (name.includes(accountNumber)) {
    return name;
  }

  return `${name} (${accountNumber})`;
};
