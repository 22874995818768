import React, { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { isPermissionAllowed } from '../utils/Utils';
import IdfTooltip from './idfComponents/idfTooltip';
import MaterialIcon from './commons/MaterialIcon';
import { Spinner } from 'reactstrap';
import useOutsideClickDropDown from '../hooks/useOutsideClickDropDown';
import LucideIcon from './commons/LucideIcon';

const MoreActions = ({
  useLucidIcon = false,
  iconSize = 18,
  lucidIcon = 'Ellipsis',
  icon = 'more_horiz',
  items,
  buttonText = '',
  onHandleOpen,
  onHandleNameEdit,
  onHandleRemove,
  onHandleDownload,
  onHandleEdit,
  onHandleReinvite,
  onHandleAdd,
  onHandleEnable,
  onHandleDisable,
  toggleClassName = 'add-more-btn icon-hover-bg rounded-circle',
  menuClassName,
  iconStyle,
  permission = {},
  loader,
  customClass,
  menuWidth = 160,
  menuPlacement = 'down',
  show,
  onToggle,
  stopPropagation = true,
  ...restProps
}) => {
  const onClickFire = {
    remove: onHandleRemove,
    edit: onHandleEdit,
    editName: onHandleNameEdit,
    reInvite: onHandleReinvite,
    add: onHandleAdd,
    download: onHandleDownload,
    open: onHandleOpen,
    enable: onHandleEnable,
    disable: onHandleDisable,
  };

  const menuRef = useRef(null);
  useOutsideClickDropDown(menuRef, show, onToggle);
  const [menuShow, setMenuShow] = useState(false);

  return (
    <>
      <Dropdown
        drop={menuPlacement}
        show={menuShow || show}
        onToggle={setMenuShow}
        style={{ opacity: 1 }}
        className={`${customClass} idf-dropdown-item-list`}
      >
        <Dropdown.Toggle
          ref={(ref) => {
            if (!ref || !stopPropagation) return;
            ref.onclick = (e) => {
              setMenuShow(!menuShow);
              e.stopPropagation();
            };
          }}
          className={`${
            toggleClassName === '' || toggleClassName?.includes('w-auto')
              ? 'add-more-btn icon-hover-bg'
              : toggleClassName
          } dropdown-hide-arrow`}
          variant="outline-link"
          id="dropdown"
          {...restProps}
        >
          <span className="d-flex align-items-center justify-content-center">
            {buttonText}
            {loader ? (
              <Spinner className="spinner-grow-xs" />
            ) : (
              <>
                {useLucidIcon ? (
                  <LucideIcon
                    icon={lucidIcon}
                    size={iconSize}
                    clazz={iconStyle}
                  />
                ) : (
                  <MaterialIcon icon={icon} clazz={`${iconStyle} fs-5`} />
                )}
              </>
            )}
          </span>
        </Dropdown.Toggle>

        {items?.length > 0 && (
          <Dropdown.Menu
            ref={menuRef}
            align="right"
            className={`border border-1 py-1 ${menuClassName}`}
            style={{ width: menuWidth }}
          >
            {items?.map((data) => (
              <>
                {data?.type === 'divider' ? (
                  <div
                    style={{ height: 1, width: '100' }}
                    className="border-top mt-1 pb-1"
                  />
                ) : (
                  <>
                    {data?.permission?.collection ? (
                      isPermissionAllowed(
                        data?.permission?.collection,
                        data?.permission?.action
                      ) && (
                        <>
                          <Dropdown.Item
                            key={data.id}
                            className={`pl-2 text-black d-flex align-items-center ${
                              data.className
                            } ${
                              data.id === 'delete' || data.id === 'remove'
                                ? data.className || 'bg-hover-danger'
                                : ''
                            }`}
                            onClick={onClickFire[data.id]}
                          >
                            {data.icon && (
                              <>
                                {useLucidIcon ? (
                                  <LucideIcon
                                    icon={data.icon}
                                    size={14}
                                    clazz={`dropdown-item-icon ${data.className}`}
                                  />
                                ) : (
                                  <MaterialIcon
                                    icon={data.icon}
                                    rounded
                                    clazz={`dropdown-item-icon ${data.className}`}
                                  />
                                )}
                              </>
                            )}
                            <span
                              className={data.textStyles}
                              style={{
                                whiteSpace: 'nowrap',
                                background: 'transparent',
                                lineHeight: 'normal',
                              }}
                            >
                              {data.name}
                            </span>
                            {data.moreDropdown}
                          </Dropdown.Item>
                          {data.moreDropdown ? data.dropdownContent : null}
                        </>
                      )
                    ) : (
                      <>
                        {data?.disabled ? (
                          <IdfTooltip text={data.disabled} placement="bottom">
                            <Dropdown.Item
                              key={data.id}
                              className={`pl-2 d-flex align-items-center text-black ${
                                data.className
                              } ${
                                data.id === 'delete' || data.id === 'remove'
                                  ? data.className || 'bg-hover-danger'
                                  : ''
                              }`}
                            >
                              <i
                                className={`material-symbols-rounded fs-5 dropdown-item-icon ${data.className}`}
                              >
                                {data.icon}
                              </i>
                              {data.name}
                            </Dropdown.Item>
                          </IdfTooltip>
                        ) : (
                          <>
                            <Dropdown.Item
                              key={data.id}
                              className={`pl-2 text-black d-flex align-items-center ${
                                data.className
                              } ${
                                data.id === 'delete' || data.id === 'remove'
                                  ? data.className || 'bg-hover-danger'
                                  : ''
                              }`}
                              onClick={onClickFire[data.id]}
                            >
                              {data.icon && (
                                <>
                                  {useLucidIcon ? (
                                    <LucideIcon
                                      icon={data.icon}
                                      size={14}
                                      clazz={`dropdown-item-icon ${data.className}`}
                                    />
                                  ) : (
                                    <MaterialIcon
                                      icon={data.icon}
                                      rounded
                                      clazz={`dropdown-item-icon ${data.className}`}
                                    />
                                  )}
                                </>
                              )}
                              <span
                                className={data.textStyles}
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                {data.name}
                              </span>
                              {data.moreDropdown}
                            </Dropdown.Item>
                            {data.moreDropdown ? data.dropdownContent : null}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};

MoreActions.defaultProps = {
  toggleClassName: '',
};

export default MoreActions;
