import React, { useEffect, useState } from 'react';
import Table from '../../../components/GenericTable';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import LucideIcon from '../../../components/commons/LucideIcon';
import { MetricCard } from '../../../components/chat/MetricCard';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import useFetchArchives from '../../../components/chat/hooks/useFetchArchives';
import { generateOneYearBalanceRecords } from '../../../components/reports/account-structure/account.structure.constants';
import Skeleton from 'react-loading-skeleton';
import useFetchConversationLogsAnalytics from './hooks/useFetchConversationLogsAnalytics';
import { numberWithCommas, setDateFormat } from '../../../utils/Utils';

const columns = [
  {
    key: 'date',
    component: 'Date',
    orderBy: 'date',
    classes: 'text-left',
  },
  {
    key: 'tenant',
    component: 'Tenant',
    orderBy: 'tenant',
    classes: 'text-left',
  },
  {
    key: 'feedback',
    component: 'Feedback',
    orderBy: 'feedback',
  },
  {
    key: 'conversation',
    component: 'Conversation',
    orderBy: 'conversation',
    classes: 'text-left',
  },
];

const FeedbackCell = ({ percentage }) => {
  const feedbackColor = percentage >= 50 ? 'positive' : 'negative';
  return (
    <div className={`feedback-cell ${feedbackColor}`}>
      <LucideIcon
        clazz="feedback-icon"
        icon={percentage >= 50 ? 'ThumbsUp' : 'ThumbsDown'}
        size={16}
      />
      <div className="feedback-bar">
        <div
          className="feedback-bar-segment green"
          style={{ flexBasis: `${percentage}%` }}
        />
        <div
          className="feedback-bar-segment red"
          style={{ flexBasis: `${100 - percentage}%` }}
        />
      </div>
    </div>
  );
};
const ConversationLog = () => {
  const [filterMonth, setFilterMonth] = useState({});
  const [contentFilter, setContentFilter] = useState({});
  const { loadingPremiumArchives, premiumArchives, fetchPremiumArchives } =
    useFetchArchives(false, 50, { hasPlans: true });
  const {
    convLogs,
    loading: loadingLogs,
    fetchOverviewLogs,
    setArchive,
    setDateRange,
  } = useFetchConversationLogsAnalytics();
  const monthOptions = generateOneYearBalanceRecords(null, true);

  const data =
    convLogs?.conversationTable?.length > 0
      ? convLogs?.conversationTable.map((log, index) => {
          return {
            id: index + 1,
            dataRow: [
              {
                key: 'date',
                component: (
                  <span>
                    {log.chatCreatedAt
                      ? setDateFormat(log.chatCreatedAt)
                      : '--'}
                  </span>
                ),
              },
              {
                key: 'tenant',
                component: <span>{log.tenantName || '--'}</span>,
              },
              {
                key: 'feedback',
                component: (
                  <FeedbackCell
                    percentage={log.averagePositiveRatingPercentage}
                  />
                ),
              },
              {
                key: 'conversation',
                component: <span>{log?.title || '--'}</span>,
              },
            ],
          };
        })
      : [];

  const handleMonthChange = (e, option) => {
    setFilterMonth(option.key);
    setDateRange({
      start: option.start,
      end: option.end,
    });
  };

  const handleContentFilterChange = (e, option) => {
    setContentFilter(option);
    setArchive(option);
  };

  const metricData = [
    {
      iconName: 'MessagesSquare',
      title: 'Total Conversations',
      value: numberWithCommas(convLogs?.totalConversations || 0),
      class: 'total-conversations',
    },
    {
      iconName: 'ThumbsUp',
      title: 'Positive Feedback',
      value: convLogs?.averagePositiveRatingPercentage
        ? `${convLogs?.averagePositiveRatingPercentage?.toFixed(0)}%`
        : '0%',
      class: 'positive-feedback-rate',
    },
    {
      iconName: 'ThumbsDown',
      title: 'Negative Feedback',
      value: numberWithCommas(convLogs?.totalNegativeRating || 0),
      class: 'negative-feedback-count',
    },
    {
      iconName: 'ChartLine',
      title: 'Avg. Daily Conversations',
      value: convLogs?.averageDailyChats
        ? numberWithCommas(Math.floor(convLogs?.averageDailyChats))
        : 0,
      class: 'avg-daily-conversations',
    },
  ];

  useEffect(() => {
    filterMonth?.start && contentFilter?.archiveId && fetchOverviewLogs();
  }, [contentFilter, filterMonth]);

  useEffect(() => {
    fetchPremiumArchives();
  }, []);

  return (
    <div className="p-0">
      <header className="d-flex px-3 pt-3 mb-3 align-items-center justify-content-between">
        <h1>Ida Conversation Log</h1>
        <div className="d-flex align-items-center gap-2">
          <ButtonFilterDropdown
            buttonText="Select Month"
            icon="calendar_today"
            options={monthOptions}
            handleFilterSelect={handleMonthChange}
            filterOptionSelected={monthOptions.find(
              (option) => option.key === filterMonth
            )}
            btnToggleStyle="btn-sm"
          />
          {loadingPremiumArchives ? (
            <Skeleton height={10} width={200} />
          ) : (
            <ButtonFilterDropdown
              buttonText="Select Content"
              options={premiumArchives}
              customKeys={['archiveId', 'title']}
              handleFilterSelect={handleContentFilterChange}
              filterOptionSelected={contentFilter}
              icon="filter_alt"
              btnToggleStyle="btn-sm min-width-200"
            />
          )}
          <ButtonIcon
            icon="download"
            classnames="btn-sm d-none"
            label="Export"
          />
        </div>
      </header>
      <div className="d-flex px-3 align-items-center gap-3 mb-3">
        {metricData.map((metric, index) => (
          <MetricCard
            key={index}
            iconName={metric.iconName}
            title={metric.title}
            value={metric.value}
            classType={metric.class}
            loading={loadingLogs}
          />
        ))}
      </div>
      <div
        className="d-flex overflow-y-auto flex-column"
        style={{ height: 'calc(100vh - 350px)' }}
      >
        <Table
          columns={columns}
          data={data}
          title="tenant-log"
          usePagination={true}
          dataInDB={true}
          tableLoading={loadingLogs}
          headClass="bg-gray-table-head border-top"
          emptyDataText="No data"
          emptyDataIcon="manage_search"
          exportToCSV={false}
          fileName="tenant-log-export"
        />
      </div>
    </div>
  );
};

export default ConversationLog;
