import { useEffect, useState } from 'react';
import ChatService from '../../../services/chat.service';

const useFetchArchivesByChat = (id) => {
  const [archives, setArchives] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchArchivesByChat = async () => {
    try {
      setLoading(true);
      const { data } = await ChatService.getChatArchives(id);
      setArchives(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    id && fetchArchivesByChat();
  }, [id]);

  return {
    archives,
    setArchives,
    loading,
    setLoading,
    fetchArchivesByChat,
  };
};

export default useFetchArchivesByChat;
