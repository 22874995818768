import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { CardSection } from '../../layouts/CardLayout';
import { DemoTenants } from '../../reports/reports.constants';
import useIsTenant from '../../../hooks/useIsTenant';
import useEngagementReportTabs from '../../reports/EngagementReportTabs';
import LatestEngagementsTable from './LatestEngagementsTable';
import useHash from '../../../hooks/useHash';
import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';

const Report = ({ children, className }) => (
  <Col xs={12} className={`${className || `p-0`}`}>
    {children}
  </Col>
);

const AddDataReport = ({
  organization,
  organizationId,
  profileInfo,
  getProfileInfo,
  refreshCounts = () => {},
}) => {
  const [showResults, setShowResults] = useState(false);
  useEffect(() => {
    setShowResults(true);
  }, []);

  const { isExcelBank, isDemo } = useIsTenant();
  const [demoTenant] = useState(isExcelBank || isDemo ? DemoTenants[0] : {});
  const { updateHash } = useHash();

  const {
    EngagementReportTiles,
    isDefaultView,
    selectedTab,
    EngagementReportDropdown,
    tabs,
    setTabs,
    setSelectedTab,
    setIsDefaultView,
  } = useEngagementReportTabs({
    organizationId,
    getProfileInfo,
    profileInfo,
    selectedTenant: demoTenant,
    refreshCounts,
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
      <CardSection
        className={`m-0 p-0 border companydetail-tab-engagement mx-auto`}
      >
        {showResults && (
          <Report className={`p-0`}>
            <Row className={`m-0 pt-0 align-items-center`}>
              <Col xl={9} lg={12} md={12} sm={12} className="px-0">
                {isDefaultView ? null : (
                  <div className="pl-3">
                    <EngagementReportDropdown />
                  </div>
                )}
              </Col>
              <Col
                className={`text-right d-flex align-items-center justify-content-end pr-3`}
              />
            </Row>
            <div className="position-relative">
              <>
                {isDefaultView ? (
                  <div className="d-flex flex-column">
                    <EngagementReportTiles />
                    <LatestEngagementsTable
                      organization={organization}
                      organizationId={organizationId}
                      setErrorMessage={setErrorMessage}
                      setSuccessMessage={setSuccessMessage}
                      refreshCounts={refreshCounts}
                      handleEditEngagement={(rpt) => {
                        const newTab = tabs.find((tb) => tb.key === rpt.type);
                        const newTabs = [...tabs].map((tb) =>
                          tb.id === newTab.id
                            ? { ...newTab, isActive: true }
                            : { ...tb, isActive: false }
                        );
                        setSelectedTab(newTab);
                        setTabs(newTabs);
                        setIsDefaultView(false);
                        updateHash(
                          `reports/${newTab.key.toLowerCase()}/id/${
                            rpt.reportId
                          }/edit`
                        );
                      }}
                      onSelectEngagement={(rpt) => {
                        const tab = tabs.find((tab) => {
                          return tab.key === rpt.type;
                        });

                        const newTabs = [...tabs].map((tb) =>
                          tb.id === tab.id
                            ? { ...tab, isActive: true }
                            : { ...tb, isActive: false }
                        );

                        setSelectedTab(tab);
                        setTabs(newTabs);
                        setIsDefaultView(false);
                        updateHash(
                          `reports/${tab.key.toLowerCase()}/id/${rpt.reportId}`
                        );
                      }}
                    />
                  </div>
                ) : (
                  <>{selectedTab.component(profileInfo, demoTenant)}</>
                )}
              </>
            </div>
          </Report>
        )}
      </CardSection>
    </>
  );
};

export default AddDataReport;
