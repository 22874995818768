import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import RightPanelModal from '../../../components/modal/RightPanelModal';
import { PROSPECT_RIGHT_PANEL_WIDTH } from '../../../utils/Utils';
import TopicIcon from '../../commons/TopicIcon';
import AIAdvisor from '../../../views/Resources/aiAdvisor/AIAdvisor';

const AIAdvisorPanel = ({ prospectModal, setProspectModal, profileInfo }) => {
  return (
    <RightPanelModal
      showModal={prospectModal}
      setShowModal={setProspectModal}
      profileInfo={profileInfo}
      containerWidth={PROSPECT_RIGHT_PANEL_WIDTH}
      Title={
        <div className="d-flex py-2 gap-2 align-items-center">
          <div className="nav-item scroll-item">
            <div className={`nav-icon working-capital forced cursor-pointer`}>
              <TopicIcon
                icon="voice_chat"
                iconBg="working-capital"
                filled={false}
                iconSize="fs-5"
              />
            </div>
          </div>
          <h4 className="mb-0">Ask AI Advisor</h4>
        </div>
      }
    >
      <AIAdvisor fromDetailUI={true} />
    </RightPanelModal>
  );
};

const AIAdvisorSidePanel = ({ profileInfo }) => {
  const [prospectModal, setProspectModal] = useState(false);

  const onSearchProspect = () => {
    setProspectModal(true);
  };

  return (
    <>
      <OverlayTrigger
        key="aiAdvisor"
        placement="left"
        overlay={
          <Tooltip
            id={`tooltip-ai-advisor`}
            className={`tooltip-profile font-weight-bold`}
          >
            <p>Ask AI Advisor</p>
          </Tooltip>
        }
      >
        <div className="nav-item scroll-item mb-2" onClick={onSearchProspect}>
          <div className={`nav-icon working-capital forced cursor-pointer`}>
            <TopicIcon
              icon="voice_chat"
              iconBg="working-capital"
              filled={false}
              iconSize="fs-5"
            />
          </div>
        </div>
      </OverlayTrigger>
      {prospectModal && (
        <AIAdvisorPanel
          prospectModal={prospectModal}
          setProspectModal={setProspectModal}
          profileInfo={profileInfo}
        />
      )}
    </>
  );
};

export default AIAdvisorSidePanel;
