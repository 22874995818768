import { useEffect, useState } from 'react';
import TransactionService from '../../../services/transaction.service';

const useFetchTransactionStream = (
  id,
  onChunkReceived,
  onComplete = () => {},
  onChatMetaReceived,
  onChatAnswerReceived
) => {
  const [transaction, setTransaction] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchTransactionStream = async (newId) => {
    try {
      let text = '';
      setLoading(true);
      const response = await TransactionService.getTransactionStream(
        newId || id
      );
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let buffer = '';
      while (!done) {
        const { value, done: streamDone } = await reader.read();
        done = streamDone;
        if (value) {
          buffer += decoder.decode(value, { stream: true });
          const parts = buffer.split('\n');
          buffer = parts.pop();
          for (const part of parts) {
            if (part.trim()) {
              try {
                const chunkData = JSON.parse(part.trim());
                if (chunkData?.result?.type === 'chatAnswerContent') {
                  setLoading(false);
                  text += chunkData.result.chunk.text;
                  onChunkReceived(text);
                } else if (chunkData?.result?.chatAnswer) {
                  onChatAnswerReceived(chunkData.result.chatAnswer);
                } else if (chunkData?.result?.chat) {
                  // title is fetched
                  onChatMetaReceived(chunkData.result.chat);
                } else if (chunkData?.result?.type === 'chatAnswerCompleted') {
                  onComplete();
                }
              } catch (err) {
                console.error('Error parsing chunk:', err);
              }
            }
          }
        }
      }
      if (buffer.trim()) {
        try {
          const chunkData = JSON.parse(buffer.trim());
          if (chunkData?.result?.type === 'chatAnswerContent') {
            text += chunkData.result.chunk.text;
            onChunkReceived(text);
            onComplete();
          }
        } catch (err) {
          console.error('Error parsing final chunk:', err);
        }
      }
      setTransaction(response);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    id && fetchTransactionStream();
  }, [id]);
  return {
    transaction,
    loading,
    setLoading,
    fetchTransactionStream,
  };
};

export default useFetchTransactionStream;
