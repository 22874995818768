import React, { useEffect, useState } from 'react';
import Table from '../../../components/GenericTable';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import { MetricCard } from '../../../components/chat/MetricCard';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import { generateOneYearBalanceRecords } from '../../../components/reports/account-structure/account.structure.constants';
import useFetchArchives from '../../../components/chat/hooks/useFetchArchives';
import Skeleton from 'react-loading-skeleton';
import useFetchUserLogAnalytics from './hooks/useFetchUserLogAnalytics';
import {
  numberWithCommas,
  roundOrShowAll,
  setDateFormat,
} from '../../../utils/Utils';

const columns = [
  {
    key: 'tenant',
    component: 'Tenant',
    width: '170px',
  },
  {
    key: 'type',
    component: 'Tenant Type',
    width: '170px',
  },
  {
    key: 'role',
    component: 'User Role',
    width: '170px',
  },
  {
    key: 'subscription',
    component: 'Subscription Type',
    width: '170px',
  },
  {
    key: 'date',
    component: 'Start Date',
    width: '170px',
  },
];

const UserLogs = () => {
  const monthOptions = generateOneYearBalanceRecords(null, true);
  const { loadingPremiumArchives, premiumArchives, fetchPremiumArchives } =
    useFetchArchives(false, 50, {
      hasPlans: true,
    });
  const {
    loading: loadingUserLogs,
    pagination,
    setPagination,
    setArchive,
    setDateRange,
    userLogs,
    fetchUserLogs,
  } = useFetchUserLogAnalytics(false, 25);

  const [selectedMonth, setSelectedMonth] = useState({});
  const [selectedContentFilter, setSelectedContentFilter] = useState({});

  const handleMonthSelect = (e, option) => {
    e.preventDefault();
    setDateRange({
      start: option.start,
      end: option.end,
    });
    setSelectedMonth(option);
  };

  const handleContentFilterSelect = (e, option) => {
    e.preventDefault();
    setArchive(option);
    setSelectedContentFilter(option);
  };

  const [sortingTable] = useState('');
  const [sortingOrder] = useState('asc');
  const [dataInDB] = useState(true);
  const [permission] = useState(true);

  const onPageChange = (page) => {
    setPagination({
      ...pagination,
      page,
    });
  };

  const data =
    userLogs?.userTable?.length > 0
      ? userLogs?.userTable.map((log, index) => {
          return {
            id: index + 1,
            dataRow: [
              {
                key: 'organization',
                component: <span>{log.tenantName}</span>,
              },
              {
                key: 'type',
                component: <span>{log.tenantType || '--'}</span>,
              },
              {
                key: 'role',
                component: <span>{log?.userTitle || '--'}</span>,
              },
              {
                key: 'subscription',
                component: (
                  <span className="text-capitalize">
                    {log.planPaymentType}{' '}
                    {log?.planPaymentPeriod
                      ? `- ${log?.planPaymentPeriod}`
                      : ''}
                  </span>
                ),
              },
              {
                key: 'date',
                component: (
                  <span>
                    {setDateFormat(log.subscriptionAssignmentAssignedAt)}
                  </span>
                ),
              },
            ],
          };
        })
      : [];
  const metricData = [
    {
      iconName: 'Building2',
      title: 'Companies (Tenants)',
      value: numberWithCommas(userLogs?.countOfSubscriptions || 0),
      class: 'total-tenants',
    },
    {
      iconName: 'Users',
      title: 'Users',
      value: numberWithCommas(userLogs?.countOfSubscriptionAssignments || 0),
      class: 'total-conversations',
    },
    {
      iconName: 'DollarSign',
      title: 'Total Sales',
      value: userLogs?.totalAmount
        ? roundOrShowAll(userLogs.totalAmount)
        : '$0',
      class: 'total-revenue',
    },
    {
      iconName: 'Users',
      title: 'Avg. Users per Tenant',
      value: numberWithCommas(
        userLogs?.averageSubscriptionAssignmentsByTenant || 0
      ),
      class: 'avg-users-tenant',
    },
  ];

  useEffect(() => {
    selectedMonth?.start && selectedContentFilter?.archiveId && fetchUserLogs();
  }, [selectedContentFilter, selectedMonth]);

  useEffect(() => {
    fetchPremiumArchives();
  }, []);

  return (
    <div className="p-0">
      <header className="d-flex px-3 pt-3 mb-3 align-items-center justify-content-between">
        <h1>Tenant User Log</h1>
        <div className="d-flex align-items-center gap-2">
          <ButtonFilterDropdown
            buttonText="Select Month"
            options={monthOptions}
            handleFilterSelect={handleMonthSelect}
            filterOptionSelected={selectedMonth}
            icon="calendar_month"
            btnToggleStyle="btn-sm"
          />
          {loadingPremiumArchives ? (
            <Skeleton height={10} width={200} />
          ) : (
            <ButtonFilterDropdown
              buttonText="Select Content"
              options={premiumArchives}
              customKeys={['archiveId', 'title']}
              handleFilterSelect={handleContentFilterSelect}
              filterOptionSelected={selectedContentFilter}
              icon="filter_alt"
              btnToggleStyle="btn-sm min-width-200"
            />
          )}
          <ButtonIcon
            icon="download"
            classnames="btn-sm d-none"
            label="Export"
          />
        </div>
      </header>

      <div className="d-flex px-3 align-items-center gap-3 mb-3">
        {metricData.map((metric, index) => (
          <MetricCard
            key={index}
            iconName={metric.iconName}
            title={metric.title}
            value={metric.value}
            classType={metric.class}
            loading={loadingUserLogs}
          />
        ))}
      </div>

      <div
        className="d-flex overflow-y-auto flex-column"
        style={{ height: 'calc(100vh - 350px)' }}
      >
        <Table
          checkbox={false}
          columns={columns}
          tableLoading={loadingUserLogs}
          headClass="bg-gray-table-head border-top"
          data={data}
          paginationInfo={pagination}
          onPageChange={onPageChange}
          emptyDataText="No User Logs"
          title="User Logs"
          permission={permission}
          dataInDB={dataInDB}
          sortingTable={sortingTable}
          sortingOrder={sortingOrder}
          noDataInDbValidation={true}
          usePagination={true}
          actionPadding="h-100"
        />
      </div>
    </div>
  );
};

export default UserLogs;
