/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import TooltipComponent from '../../components/lesson/Tooltip';
import { Button } from 'react-bootstrap';
import {
  ChatModels,
  getChatModelByKey,
  InputDisplayType,
  maxFileLimit,
  PromptStyles,
  QuestionTypes,
  validTypes,
} from './constants';
import DropZone from './DropZone';
import { Spinner } from 'reactstrap';
import ChatService from '../../services/chat.service';
import useHash from '../../hooks/useHash';
import { useIdaContext } from '../../contexts/IdaContext';
import Suggestions from './Suggestions';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import LucideIcon from '../commons/LucideIcon';
import useSelectKnowledgePopup from './modals/SelectKnowledgeSource';
import TrioLoader from './loaders/TrioLoader';
import FilesDropdown from './FilesDropdown';
import {
  AttachMaxFilesAllowedMsg,
  getRandomUUID,
  isModuleAllowed,
} from '../../utils/Utils';
import { useLocation } from 'react-router-dom';
import { useTenantContext } from '../../contexts/TenantContext';
import { PermissionsConstants } from '../../utils/permissions.constants';
import useFetchTransactionStream from './hooks/useFetchTransactionStream';
import FilesAttachDropdown from './FilesAttachDropdown';
import PromptStyleDropdown from './PromptStyleDropdown';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';

const ExpandCharactersCount = 100;

const LoaderAnimation = () => {
  return (
    <div
      className="position-absolute top-0 start-0 w-100 h-100"
      style={{
        background:
          'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.7), transparent)',
        maskImage: 'linear-gradient(to right, transparent, black, transparent)',
        WebkitMaskImage:
          'linear-gradient(to right, transparent, black, transparent)',
        animation: 'shine 1.5s ease-in-out infinite',
      }}
    />
  );
};

export const AiLoader = ({ type }) => {
  return (
    <div
      className="position-absolute"
      style={{ top: type === 'thread_chat' ? -10 : -55 }}
    >
      <div className="position-relative d-flex align-items-center gap-1 py-2">
        <div className="d-flex align-items-center justify-content-center gap-1">
          <TrioLoader />
          <span className="font-weight-medium">Thinking</span>
        </div>
      </div>
    </div>
  );
};

const SelectedKnowledge = ({
  list,
  openModal,
  onClear,
  useAllAccessibleArchives,
}) => {
  return (
    <div className="d-flex align-items-center transition-all hover-actions-none gap-1">
      <span
        onClick={openModal}
        className="d-flex cursor-pointer align-items-center border-1 border-primary-soft-100 rounded-md bg-primary-soft py-1 px-2 gap-1"
      >
        <LucideIcon icon="SquareLibrary" size={14} clazz="text-gray-800" />
        {list.length === 1 ? (
          <TextOverflowTooltip
            maxLength={20}
            textStyle="font-size-sm2 text-truncate text-gray-800"
            text={list[0].title}
          />
        ) : (
          <TooltipComponent list={list.map((l) => l.title)}>
            <span className="font-size-sm2 text-gray-800">
              Knowledge ({list.length}):{' '}
              {list[0].title.substring(
                0,
                list[0].title.length > 10 ? 10 : list[0].title.length
              ) + '...'}
            </span>
          </TooltipComponent>
        )}
        <a
          className="cursor-pointer text-gray-800 action-items font-size-sm2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClear();
          }}
        >
          <LucideIcon icon="X" size={14} />
        </a>
      </span>
    </div>
  );
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
}

const RandomSuggestions = [
  'Ghost Jobs Flood the Market',
  'AI Grandma Ties Up Scammers',
  'DeepMind Releases AlphaFold Code',
  "China's Solar Great Wall",
  'Foundational Von Neumann Architecture',
  'Waymo Expands in Los Angeles',
];

const AskAnything = ({
  placeholder = 'How can I help you today?',
  displayType = InputDisplayType.Home,
  selectedChatModel,
  chatId,
}) => {
  // displayType logic:
  // Home/NewChat: if we are on home page and enter text and hit submit we need to call api and then switch to chat/history view
  // Thread: since we are on that chat history then we dont need to route to new page we will refresh the same view
  // AskIdaModal: there is side modal opens from company/contact details for that we show compact text input
  const [input, setInput] = useState('');
  const [files, setFiles] = useState([]);
  const [isMicActive, setIsMicActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { updateHash } = useHash();
  const containerRef = useRef(null);
  const fileInputRef = useRef(null);
  const recognitionRef = useRef(null);
  const textareaRef = useRef(null);
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [suggestionsList, setSuggestionsList] = useState(RandomSuggestions);
  const [suggestionsHovered, setSuggestionsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState({
    key: PromptStyles.Normal,
    title: 'Normal',
  });
  const [selectedModel, setSelectedModel] = useState(
    selectedChatModel?.model
      ? getChatModelByKey(selectedChatModel?.model)
      : ChatModels[0]
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] =
    useState(RandomSuggestions);
  const {
    selectedChat,
    setNewQuestion,
    prompt,
    setPrompt,
    trigger,
    setTrigger,
    mask,
    setMask,
    setKnowledgeSource,
  } = useIdaContext();

  const { tenant } = useTenantContext();
  const isFileAttachmentsAllowed = isModuleAllowed(
    tenant?.modules,
    PermissionsConstants.Ida.FileAttachment
  );

  const [useAllAccessibleArchives, setUseAllAccessibleArchives] =
    useState(false);
  const [selectedArchives, setSelectedArchives] = useState([]);

  const isHome = displayType === InputDisplayType.Home;
  const isAskIdaModal = displayType === InputDisplayType.AskIdaModal;
  const isThread = displayType === InputDisplayType.Thread;

  const {
    SelectKnowledgeSourceModal,
    selected: selectedKnowledge,
    setSelected: setSelectedKnowledge,
    setShow: setKnowledgeModal,
  } = useSelectKnowledgePopup((selectAll) => {
    setUseAllAccessibleArchives(selectAll);
  });

  useEffect(() => {
    setSelectedArchives(selectedKnowledge);
  }, [selectedKnowledge]);

  const handleUseKnowledge = () => {
    setKnowledgeModal(true);
  };

  const focusInput = () => {
    if (textareaRef.current) {
      setTimeout(() => {
        textareaRef.current.focus(); // add focus
      }, 10);
    }
  };

  useEffect(() => {
    if (!input && (isThread || isAskIdaModal || isHome)) {
      setTimeout(() => {
        focusInput(); // Ensure focus after rerender
      }, 100);
    }
  }, [input, isThread, isAskIdaModal, isHome]);

  const handleFileUpload = (event) => {
    if (!event.target.files.length) return;

    if (files.length > maxFileLimit) {
      return setErrorMessage(AttachMaxFilesAllowedMsg);
    }
    const uploadedFiles = Array.from(event.target.files);
    if (files.length < maxFileLimit) {
      setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
      setIsHovered(false);
    }

    event.target.value = ''; // Reset input value to allow re-upload of the same file
  };

  const handleDrop = (acceptedFiles) => {
    const newImages = acceptedFiles.filter((file) =>
      validTypes.includes(file.type)
    );

    if (acceptedFiles.length > maxFileLimit) {
      return setErrorMessage(AttachMaxFilesAllowedMsg);
    }

    if (files.length < maxFileLimit) {
      setFiles((prevImages) => [...prevImages, ...newImages]);
      const invalidFiles = acceptedFiles.filter(
        (file) => !validTypes.includes(file.type)
      );
      if (invalidFiles.length > 0) {
        setErrorMessage(
          `${invalidFiles
            .map((f) => f.type)
            .join(', ')} file(s) are not supported.`
        );
      }
    }
  };

  const handleRemoveFile = (index) => {
    // if index exists then remove that file otherwise all files
    setFiles((prevFiles) =>
      typeof index === 'number' ? prevFiles.filter((_, i) => i !== index) : []
    );
  };

  const handleAttachClick = () => {
    fileInputRef.current.click();
  };

  // eslint-disable-next-line no-unused-vars
  const handleMicClick = () => {
    if (recognitionRef.current) {
      if (isMicActive) {
        recognitionRef.current.stop();
        setIsMicActive(false);
      } else {
        recognitionRef.current.start();
        setIsMicActive(true);
      }
    }
  };

  useEffect(() => {
    if ('webkitSpeechRecognition' in window) {
      const WebkitSpeechRecognition = window.webkitSpeechRecognition;
      recognitionRef.current = new WebkitSpeechRecognition();
      recognitionRef.current.continuous = false;
      recognitionRef.current.interimResults = false;

      recognitionRef.current.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setInput(transcript);
      };

      recognitionRef.current.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsMicActive(false);
      };

      recognitionRef.current.onend = () => {
        setIsMicActive(false);
      };
    } else {
      console.error('Speech recognition not supported in this browser.');
    }
  }, []);

  const fakeDelay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const { fetchTransactionStream } = useFetchTransactionStream(
    null,
    (chunk) => {
      setLoading(false);
      setNewQuestion((prevState) => ({
        ...prevState,
        question: input,
        answer: { text: chunk, animate: true },
      }));
    },
    () => {},
    () => {},
    (chatAnswer) => {
      setNewQuestion((prevState) => ({
        ...prevState,
        chatAnswer,
      }));
    }
  );

  const createChat = async (selected) => {
    const typedOrSelected = selected || input;
    if (!typedOrSelected?.trim()) {
      return;
    }

    const createQuestionInChat = async (chatId, payload) => {
      let transaction;
      setInput('');
      if (files.length) {
        // add file to chat as well.
        transaction = await ChatService.createChatQuestionWithFile(
          chatId,
          payload,
          files
        );
        setFiles([]);
      } else {
        const response = await ChatService.createChatQuestionStream(
          chatId,
          payload.question
        );
        transaction = await response.json();
      }
      await fetchTransactionStream(transaction.transactionId);
    };

    // add question right in UI as user press enter
    const addTempQuestion = (id, type) => {
      const tempQuestion = {
        text: input,
        type,
        animated: true,
        mask,
        chatQuestionId: getRandomUUID(),
        chatId: id,
      };
      setNewQuestion(tempQuestion);
    };

    const startChatInstantly = (payload, triggerFrom) => {
      // instant add in UI
      const feTransactionId = getRandomUUID();
      const newQuestion = {
        chatQuestionId: feTransactionId,
        type: QuestionTypes.Home,
        style: selectedStyle.key,
        text: payload.question.text,
        model: selectedModel.key,
        files,
        mask,
        feTransactionId,
        payload,
        chatAnswers: [
          {
            type: QuestionTypes.Transaction,
            feTransactionId,
            triggerFrom,
          },
        ],
      };
      setNewQuestion(newQuestion);
      return feTransactionId;
    };

    try {
      if (files.length > maxFileLimit) {
        return setErrorMessage(AttachMaxFilesAllowedMsg);
      }

      if (isHome) {
        setIsDisabled(true); // disable InputField for home page
        textareaRef.current.blur();
        await fakeDelay(1500); // fake delay for 1.5 seconds
      }

      setLoading(true);
      const payload = {
        useAllAccessibleArchives,
        question: {
          text: typedOrSelected,
          type: 'text',
        },
        ...(selectedArchives?.length && {
          archiveIds: selectedArchives.map((l) => l.archiveId),
        }),
      };

      let feTransactionId;
      switch (displayType) {
        case InputDisplayType.NewChat:
        case InputDisplayType.Home:
          feTransactionId = startChatInstantly(payload, InputDisplayType.Home);
          textareaRef.current.blur();
          updateHash(`#chats/${feTransactionId}/new`);
          break;

        case InputDisplayType.Thread:
          if (chatId) {
            addTempQuestion(chatId, QuestionTypes.Thread);
            setInput('');
            await createQuestionInChat(chatId, payload);
          }
          break;

        case InputDisplayType.AskIdaModal:
          setInput('');
          setPrompt('');
          if (selectedChat?.chatId) {
            addTempQuestion(selectedChat.chatId, QuestionTypes.Home);
            await createQuestionInChat(selectedChat.chatId, payload);
          } else {
            feTransactionId = startChatInstantly(
              payload,
              InputDisplayType.AskIdaModal
            );
          }
          break;

        default:
          break;
      }

      setInput('');
      setPrompt('');
      setTrigger('');
      setMask('');
      setFiles([]);
      setSelectedArchives([]);
      setIsDisabled(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onHandleChange = (e) => {
    const { value, selectionStart } = e.target;
    setInput(value);
    setHighlightedIndex(-1);

    if ((isThread || isAskIdaModal) && value) {
      setTimeout(() => {
        focusInput(selectionStart); // focus input with Pass cursor position
      }, 100);
    }

    const found = suggestionsList?.filter((item) => {
      return item?.toLowerCase().includes(value?.toLowerCase());
    });

    if (value) {
      setIsSuggestionsOpen(true);
      setFilteredSuggestions(found.slice(0, 5));
    } else {
      setIsSuggestionsOpen(false);
      setFilteredSuggestions(suggestionsList.slice(0, 5));
      setSuggestionsList(RandomSuggestions);
    }
  };

  const onHandleSelect = (value) => {
    setInput(value);
    setIsSuggestionsOpen(false);
    setHighlightedIndex(-1);
    createChat(value);
  };

  const handleEnter = (e) => {
    // this could be improved? I guess
    if (
      (e.metaKey || e.ctrlKey) &&
      e.key === 'k' &&
      location.pathname.includes('/ida')
    ) {
      e.preventDefault();
      updateHash('chat');
      return;
    }
    if (isSuggestionsOpen) {
      if (e.key === 'ArrowDown') {
        setHighlightedIndex((prev) =>
          prev < filteredSuggestions.length - 1 ? prev + 1 : 0
        );
      } else if (e.key === 'ArrowUp') {
        setHighlightedIndex((prev) =>
          prev > 0 ? prev - 1 : filteredSuggestions.length - 1
        );
      } else if (e.key === 'Enter') {
        // If there is a highlighted index, select the suggestion
        if (
          highlightedIndex >= 0 &&
          highlightedIndex < filteredSuggestions?.length
        ) {
          onHandleSelect(filteredSuggestions[highlightedIndex]);
          e.preventDefault();
        } else {
          createChat();
        }
      }
    } else {
      if (e.key === 'Enter') {
        createChat();
      }
    }
  };

  const handleFocus = (e) => {
    containerRef.current?.classList?.add('focused');
    const textarea = textareaRef.current;
    textarea.selectionStart = textarea.value.length;
    textarea.selectionEnd = textarea.value.length;
    if (input) {
      setIsSuggestionsOpen(true);
    }
  };

  const handleBlur = (e) => {
    containerRef.current?.classList?.remove('focused');

    if (!suggestionsHovered) {
      setTimeout(() => {
        setIsSuggestionsOpen(false);
      }, 300);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    setInput((prev) => prev + text);
  };

  const handleCameraClick = async (e) => {
    e.preventDefault();
    let stream = null;
    let video = null; // declare video variable to clear later

    try {
      // eslint-disable-next-line no-undef
      const controller = new CaptureController();

      stream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          cursor: 'always',
          displaySurface: 'window',
        },
        audio: false,
        selfBrowserSurface: 'exclude',
        controller, // attach the controller for conditional focus
      });

      // if the captured surface isn't a tab or window, this call may throw.
      try {
        controller.setFocusBehavior('no-focus-change');
      } catch (error) {
        console.log("not a tab or window, can't set focus behavior");
      }

      video = document.createElement('video');
      video.srcObject = stream;

      await new Promise((resolve) => {
        video.onloadedmetadata = () => resolve();
      });

      await video.play();

      const videoTrack = stream.getVideoTracks()[0];
      const settings = videoTrack.getSettings();

      const canvas = document.createElement('canvas');
      canvas.width = settings.width || video.videoWidth;
      canvas.height = settings.height || video.videoHeight;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      }

      const blob = await new Promise((resolve) => {
        canvas.toBlob((b) => resolve(b), 'image/png', 0.95);
      });

      const file = new File([blob], `screenshot-${Date.now()}.png`, {
        type: 'image/png',
      });

      setFiles((prevFiles) => [...prevFiles, file]);
      return file;
    } catch (err) {
      console.error('Screen capture error:', err);
      throw err;
    } finally {
      // stop all tracks in the stream
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (video) {
        video.srcObject = null;
      }
    }
  };

  const triggerCameraClick = (event) => {
    event?.preventDefault();
    handleCameraClick(event)
      .then(() => {
        console.log('Screenshot captured successfully');
      })
      .catch((err) => {
        console.error('Capture failed:', err);
      });
  };

  useEffect(() => {
    prompt && setInput(prompt);
  }, [prompt]);

  useEffect(() => {
    if (trigger) {
      setInput(trigger);
      createChat(trigger);
    }
  }, [trigger]);

  return (
    <>
      <AlertWrapper>
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
      </AlertWrapper>

      <SelectKnowledgeSourceModal />

      <DropZone
        showFullDrag
        isAskIdaModal={isAskIdaModal}
        enabled={isFileAttachmentsAllowed}
        onDrop={handleDrop}
      >
        <div className={!isAskIdaModal ? 'home-chat-container' : ''}>
          <div
            className={`chat-input-container position-relative ${
              isThread && input?.length < ExpandCharactersCount
                ? 'thread-input-'
                : ''
            } ${isDisabled ? 'disabled' : ''} `}
            ref={containerRef}
          >
            {isDisabled && <LoaderAnimation />}

            {isAskIdaModal && loading ? <AiLoader type={displayType} /> : null}

            {isAskIdaModal && input?.length < ExpandCharactersCount ? (
              <div className="controls bottom-0">
                <div className="d-flex flex-fill align-items-center">
                  <input
                    id="chatInput"
                    name="chatInput"
                    type="text"
                    placeholder={placeholder}
                    style={{ whiteSpace: 'pre-wrap' }}
                    className="form-control border-0 shadow-0 font-size-md flex-fill"
                    value={input}
                    ref={textareaRef}
                    onKeyDown={handleEnter}
                    onChange={onHandleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onPaste={handlePaste}
                  />
                </div>

                <div className="d-flex align-items-center gap-1">
                  <>
                    {isFileAttachmentsAllowed ? (
                      <>
                        <FilesAttachDropdown
                          files={files}
                          handleAttachClick={handleAttachClick}
                          triggerCameraClick={triggerCameraClick}
                          handleKnowledgeSelect={handleUseKnowledge}
                          isHome={isHome}
                        />

                        {files?.length ? (
                          <FilesDropdown
                            files={files}
                            group={true}
                            isAskIdaModal={isAskIdaModal}
                            handleAttachClick={handleAttachClick}
                            handleRemoveFile={handleRemoveFile}
                            triggerCameraClick={triggerCameraClick}
                          />
                        ) : (
                          ''
                        )}

                        <input
                          type="file"
                          accept={validTypes.join(',')}
                          multiple
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileUpload}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>

                  <Button
                    type="submit"
                    disabled={!input?.trim().length || loading}
                    className="btn rounded-md size-30 btn-icon"
                    onClick={() => createChat(input)}
                    variant={!input?.trim().length ? 'secondary' : 'primary'}
                  >
                    <LucideIcon
                      icon="ArrowUp"
                      size={16}
                      clazz="cursor-pointer"
                    />
                  </Button>
                </div>
              </div>
            ) : (
              <div
                className={
                  displayType === InputDisplayType.KnowledgeThread
                    ? 'disabled pe-none'
                    : ''
                }
              >
                <textarea
                  name="chatInput"
                  placeholder={placeholder}
                  className="form-control font-size-md"
                  rows={isThread || isAskIdaModal ? 1 : 2}
                  id="chatInput"
                  value={input}
                  ref={textareaRef}
                  onKeyDown={handleEnter}
                  onChange={onHandleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onPaste={handlePaste}
                  style={{
                    whiteSpace: 'pre-wrap',
                    height:
                      input?.length > ExpandCharactersCount ? 150 : 'auto',
                  }}
                />
                <div className="controls px-1_2 w-100">
                  <div
                    className="d-flex align-items-center justify-content-between gap-2"
                    style={{ width: 'calc(100% - 16px)' }}
                  >
                    {isFileAttachmentsAllowed ? (
                      <>
                        <FilesAttachDropdown
                          files={files}
                          handleAttachClick={handleAttachClick}
                          triggerCameraClick={triggerCameraClick}
                          handleKnowledgeSelect={handleUseKnowledge}
                          isHome={isHome}
                        />
                        {!isAskIdaModal && (
                          <PromptStyleDropdown
                            selectedStyle={selectedStyle}
                            selectedModel={selectedModel}
                            readOnly={selectedChatModel?.model || ''}
                            handleSelectModel={(e, model) => {
                              setSelectedModel(model);
                            }}
                            handlePromptStyle={(e, style) => {
                              setSelectedStyle(style);
                            }}
                          />
                        )}
                        {!isThread && !isAskIdaModal && (
                          <>
                            {selectedArchives?.length ||
                            useAllAccessibleArchives ? (
                              <SelectedKnowledge
                                list={selectedArchives}
                                openModal={handleUseKnowledge}
                                useAllAccessibleArchives={
                                  useAllAccessibleArchives
                                }
                                onClear={() => {
                                  setSelectedArchives([]);
                                  setSelectedKnowledge([]);
                                  setUseAllAccessibleArchives(false);
                                  setKnowledgeSource('');
                                }}
                              />
                            ) : null}
                          </>
                        )}
                        <input
                          type="file"
                          accept={validTypes.join(',')}
                          multiple
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileUpload}
                        />
                      </>
                    ) : (
                      ''
                    )}
                    <div className="d-flex align-items-center ml-auto gap-2">
                      {files?.length ? (
                        <FilesDropdown
                          files={files}
                          group={true}
                          isAskIdaModal={isAskIdaModal}
                          handleAttachClick={handleAttachClick}
                          handleRemoveFile={handleRemoveFile}
                          triggerCameraClick={triggerCameraClick}
                        />
                      ) : (
                        ''
                      )}
                      <TooltipComponent placement="top" title="Send message">
                        <Button
                          type="submit"
                          disabled={!input?.trim().length || loading}
                          className="btn btn-icon size-30 rounded-md icon-ignore"
                          onClick={() => createChat(input)}
                          variant={
                            !input?.trim().length ? 'secondary' : 'primary'
                          }
                        >
                          {displayType === InputDisplayType.Home && loading ? (
                            <Spinner className="spinner-grow-xs" />
                          ) : (
                            <LucideIcon icon="ArrowUp" clazz="cursor-pointer" />
                          )}
                        </Button>
                      </TooltipComponent>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </DropZone>
    </>
  );
};

export default AskAnything;
