import { useEffect, useState } from 'react';
import ChatService from '../../../services/chat.service';
import { getChatModelByKey } from '../constants';

const useFetchAIModels = (onLoad = true) => {
  const [models, setModels] = useState([]);
  const [availableModels, setAvailableModels] = useState([]);
  const [reasoningModels, setReasoningModels] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchAIModels = async () => {
    try {
      setLoading(true);
      const data = await ChatService.getAIModels();
      const allModels = data?.map((d) => ({
        ...getChatModelByKey(d.model),
      }));
      setModels(allModels);
      // FOR time being.
      setAvailableModels(allModels.filter((f) => f.type === 'normal'));
      setReasoningModels(allModels.filter((f) => f.type === 'pro'));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onLoad && fetchAIModels();
  }, [onLoad]);

  return {
    models,
    availableModels,
    reasoningModels,
    setModels,
    loading,
    setLoading,
    fetchAIModels,
  };
};

export default useFetchAIModels;
