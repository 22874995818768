import {
  getIconByMimeType,
  InputDisplayType,
  MenuItemsKeysAndIcons,
} from '../constants';
import ThreadLayout from '../layout/ThreadLayout';
import Avatar from '../../Avatar';
import AskAnything from '../AskAnything';
import moment from 'moment';
import MoreActions from '../../MoreActions';
import TooltipComponent from '../../lesson/Tooltip';
import useFetchArchive from '../hooks/useFetchArchive';
import Skeleton from 'react-loading-skeleton';
import useFetchArchiveFiles from '../hooks/useFetchArchiveFiles';
import useCreateKnowledgeModal from '../modals/CreateKnowledge';
import useKnowledgeContentsModal from '../modals/KnowledgeContents';
import DeleteConfirmationModal from '../../modal/DeleteConfirmationModal';
import React, { useState } from 'react';
import ArchiveService from '../../../services/archive.service';
import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import useHash from '../../../hooks/useHash';
import { isModuleAllowed, numberWithCommas } from '../../../utils/Utils';
import LucideIcon from '../../commons/LucideIcon';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import useIsTenant from '../../../hooks/useIsTenant';
import { PermissionsConstants } from '../../../utils/permissions.constants';
import { useTenantContext } from '../../../contexts/TenantContext';

const { isDemo } = useIsTenant();
const sectionVisible = isDemo ? '' : 'd-none';

const InstructionsAndContent = ({ archive, onUpdate }) => {
  const { files, loading, pagination, fetchArchiveFiles } =
    useFetchArchiveFiles(archive?.archiveId, 5);
  const { tenant } = useTenantContext();
  const isFileAttachmentsAllowed = isModuleAllowed(
    tenant?.modules,
    PermissionsConstants.Ida.FileAttachment
  );
  /* const files = [
    {
      id: 1,
      type: 'pdf',
      icon: 'picture_as_pdf',
      name: 'upload.pdf',
    },
    {
      id: 2,
      type: 'docx',
      icon: 'description',
      name: 'document.docx',
    },
    {
      id: 3,
      type: 'csv',
      icon: 'csv',
      name: 'files.csv',
    },
  ]; */
  const {
    CreateKnowledgeModal,
    setShowModal: setShowEditArchiveModal,
    setKnowledge,
  } = useCreateKnowledgeModal(onUpdate);
  const {
    showModal,
    KnowledgeContentsModal,
    setShowModal: setShowContentsModal,
    setKnowledge: setKnowledgeContentModal,
  } = useKnowledgeContentsModal(() => {
    fetchArchiveFiles();
  });

  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <CreateKnowledgeModal />
      {showModal && <KnowledgeContentsModal />}
      <div className="bg-gray-table-head position-sticky top-0 border rounded d-flex flex-column">
        <div className={`p-3 flex-column border-bottom`}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex gap-2 align-items-center">
              <LucideIcon size={22} icon="AlignLeft" />
              <h4 className="mb-0">Instructions</h4>
            </div>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setKnowledge(
                  Object.keys(archive).length > 1
                    ? archive
                    : { archiveId: archive.archiveId }
                );
                setShowEditArchiveModal(true);
              }}
              className="text-muted-100 font-weight-semi-bold icon-hover-bg-soft-primary rounded fs-7"
            >
              Edit
            </a>
          </div>
          <p
            className="font-size-sm2 pt-2 px-1 mb-0"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {archive?.prompt || '--'}
          </p>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center border-bottom justify-content-between">
            <div className="d-flex gap-2 p-3 align-items-center">
              <LucideIcon size={20} icon="Folder" />
              <h4 className="mb-0">Sources</h4>
            </div>
          </div>
          <div className="font-size-sm2 pb-2 mb-0">
            {loading ? (
              <div className="p-3">
                <Skeleton count={3} height={10} width="100%" />
              </div>
            ) : (
              <>
                <div
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="px-3 pt-3 d-flex align-items-center cursor-pointer justify-content-between"
                >
                  <div className="d-flex px-1 fs-7_1 align-items-center gap-1">
                    <LucideIcon
                      icon={isExpanded ? 'ChevronDown' : 'ChevronRight'}
                      size={20}
                    />
                    <span>Files</span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <span>{numberWithCommas(pagination.count)}</span>
                    {isFileAttachmentsAllowed ? (
                      <div className="text-muted">
                        <TooltipComponent
                          capitalizeText={false}
                          title="Add Files"
                        >
                          <a
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              setKnowledgeContentModal(
                                Object.keys(archive).length > 1
                                  ? archive
                                  : { archiveId: archive.archiveId }
                              );
                              setShowContentsModal(true);
                            }}
                            className="icon-hover-bg-soft-primary cursor-pointer"
                          >
                            <LucideIcon icon="Plus" />
                          </a>
                        </TooltipComponent>
                      </div>
                    ) : null}
                  </div>
                </div>
                {isExpanded && (
                  <div className="px-4 pt-2">
                    {files.map((file) => (
                      <div
                        className="nav-link text-nowrap content-item cursor-pointer p-1 fs-7_1 d-flex gap-1 align-items-center"
                        key={file.id}
                      >
                        <div className="d-flex align-items-center">
                          <LucideIcon
                            size={18}
                            icon={getIconByMimeType(file.file.mimeType).icon}
                            color={getIconByMimeType(file.file.mimeType).color}
                          />
                        </div>
                        <TextOverflowTooltip
                          maxLength={20}
                          textStyle="fs-7_1"
                          text={file?.file?.downloadName}
                        />
                      </div>
                    ))}
                    {pagination?.count > 5 && (
                      <div className="pt-2">
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setKnowledgeContentModal(
                              Object.keys(archive).length > 1
                                ? archive
                                : { archiveId: archive.archiveId }
                            );
                            setShowContentsModal(true);
                          }}
                          className="py-2 px-3 nav-link bg-white border bg-hover-gray transition cursor-pointer rounded-pill d-flex"
                        >
                          <span className="fs-7 font-weight-medium text-center w-100">
                            View all
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Threads = ({ threads }) => {
  return (
    <div className={sectionVisible}>
      <h4 className="d-flex mt-4 border-bottom align-items-center gap-1 pb-3">
        <LucideIcon icon="History" />
        Chat History
      </h4>
      <div className="d-flex flex-column mt-3">
        {threads?.length > 0 ? (
          <>
            {threads.map((thread, index) => (
              <div
                key={thread.id}
                className={`py-2 rounded ${
                  index >= 0 && index !== threads.length - 1
                    ? 'border-bottom'
                    : index === threads.length - 1
                    ? 'border-bottom-0'
                    : ''
                }`}
              >
                <div className="d-flex align-items-center pb-2 justify-content-between">
                  <h5 className="mb-0">{thread.title}</h5>
                  <Avatar user={thread.owner} defaultSize="xs" />
                </div>
                <p className="mb-0 text-clamp">{thread.description}</p>
                <div className="d-flex pt-2 align-items-center justify-content-between">
                  <div className="fs-8 d-flex align-items-center font-weight-medium text-muted">
                    <LucideIcon icon="History" size={14} />
                    <span>{moment(thread.lastUpdated).fromNow()}</span>
                  </div>
                  <a className="icon-hover-bg cursor-pointer">
                    <LucideIcon icon="Ellipsis" />
                  </a>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="bg-gray-table-head rounded p-3 text-center border">
            Threads created in this Knowledge can be found here.
          </div>
        )}
      </div>
    </div>
  );
};

const Details = ({ id }) => {
  const { archive: knowledge, setArchive, loading } = useFetchArchive(id);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { updateHash } = useHash();
  const actionItems = [
    {
      id: 'edit',
      icon: 'Pencil',
      name: 'Edit Knowledge',
    },
    {
      id: 'remove',
      icon: 'Trash2',
      name: 'Delete Knowledge',
    },
  ];

  const handleRemove = () => {
    setComponentsToDelete([knowledge]);
    setOpenDeleteModal(true);
  };

  const handleEditName = () => {
    setKnowledge(knowledge);
    setShowEditArchiveModal(true);
  };

  const ActionControls = () => {
    return (
      <div className="d-flex align-items-center gap-1">
        <MoreActions
          useLucidIcon
          items={actionItems}
          onHandleRemove={(e) => {
            e.stopPropagation();
            handleRemove();
          }}
          onHandleEdit={(e) => {
            e.stopPropagation();
            handleEditName();
          }}
          toggleClassName="w-auto p-0 h-auto"
        />
        {/* <ShareOptions /> */}
      </div>
    );
  };

  const onUpdate = (updatedKnowledge) => {
    setArchive({
      ...knowledge,
      title: updatedKnowledge.title,
      description: updatedKnowledge.description,
      icon: updatedKnowledge.icon,
    });
  };

  const {
    CreateKnowledgeModal,
    setShowModal: setShowEditArchiveModal,
    setKnowledge,
  } = useCreateKnowledgeModal(onUpdate);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [componentsToDelete, setComponentsToDelete] = useState([]);

  const handleDeleteKnowledge = async () => {
    try {
      await ArchiveService.deleteArchive(knowledge.archiveId);
      setSuccessMessage('Knowledge Deleted.');
      setTimeout(() => {
        updateHash('#knowledge');
      }, 500);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <AlertWrapper>
        {successMessage && (
          <Alert
            color="success"
            message={successMessage}
            setMessage={setSuccessMessage}
          />
        )}
        {errorMessage && (
          <Alert
            color="danger"
            message={errorMessage}
            setMessage={setErrorMessage}
          />
        )}
      </AlertWrapper>
      <ThreadLayout
        ownerAndDate={<ActionControls />}
        heading={MenuItemsKeysAndIcons.Knowledge}
        controls={null}
        position="align-content-start"
      >
        <CreateKnowledgeModal />
        <DeleteConfirmationModal
          showModal={openDeleteModal}
          setShowModal={setOpenDeleteModal}
          setSelectedCategories={setComponentsToDelete}
          event={handleDeleteKnowledge}
          itemsConfirmation={componentsToDelete}
          heading="Delete Knowledge"
          description="Are you sure you want to delete this knowledge?"
        />
        {loading ? (
          <div className="pt-3">
            <Skeleton count={3} className="mb-2" height={10} width="100%" />
          </div>
        ) : (
          <div style={{ maxWidth: 1100 }} className="row py-3">
            <div className="col-md-8">
              <h3 className="mb-2 d-flex align-items-center gap-2">
                <div className="p-2 bg-gray-table-head rounded-circle d-flex align-items-center justify-content-center">
                  {knowledge.icon ? (
                    <span
                      className="fs-4 d-inline-flex justify-content-center align-items-center"
                      style={{ height: 28, width: 28 }}
                      dangerouslySetInnerHTML={{
                        __html: `&#x${knowledge?.icon};`,
                      }}
                    />
                  ) : (
                    <LucideIcon
                      icon="Files"
                      filled={knowledge.filled}
                      size={22}
                    />
                  )}
                </div>
                {knowledge.title}
              </h3>
              {knowledge.description ? (
                <p className="mb-4">{knowledge.description}</p>
              ) : (
                <p className="text-muted">Description</p>
              )}
              <div className={`position-relative ${sectionVisible}`}>
                <AskAnything
                  placeholder="New Thread"
                  displayType={InputDisplayType.KnowledgeThread}
                />
              </div>
              <Threads threads={knowledge.threads || []} />
            </div>
            <div className="col-md-4">
              <InstructionsAndContent
                archive={
                  Object.keys(knowledge).length > 1
                    ? knowledge
                    : { archiveId: id }
                }
                onUpdate={onUpdate}
              />
            </div>
          </div>
        )}
      </ThreadLayout>
    </>
  );
};

export default Details;
