import React, { useRef, useState } from 'react';
import useOnResize from '../../../hooks/useOnResize';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import useHash from '../../../hooks/useHash';
import IdfTooltip from '../../idfComponents/idfTooltip';
import LucideIcon from '../../commons/LucideIcon';
import Disclaimer from '../Disclaimer';
import { useNavBarShortKey } from '../../../hooks/useNavBarShortKey';
import { useTenantContext } from '../../../contexts/TenantContext';
import { isModuleAllowed } from '../../../utils/Utils';
import { PermissionsConstants } from '../../../utils/permissions.constants';

const ThreadLayout = ({
  heading,
  ownerAndDate,
  controls,
  position = 'align-content-end',
  children,
}) => {
  const { hash, updateHash } = useHash();
  const contentRef = useRef(null);
  const scrollableRef = useRef(null);
  const [shouldAutoscroll] = useState(true);
  const { tenant } = useTenantContext();
  const isIdaAllowed = isModuleAllowed(
    tenant?.modules,
    PermissionsConstants.Ida.Ida
  );
  useOnResize(contentRef, () => {
    const scrollableEl = scrollableRef.current;
    if (!scrollableEl) return;

    const maxScrollHeight =
      scrollableEl.scrollHeight - scrollableEl.clientHeight;

    if (shouldAutoscroll) {
      scrollableEl.scrollTop = maxScrollHeight;
    }
  });
  useNavBarShortKey(['command', 'k'], () => {
    isIdaAllowed && updateHash('chat');
  });
  return (
    <div className="d-flex h-100 flex-column">
      <div className="border-bottom px-3 py-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="flex-1">
            <h3 className="mb-0 d-flex font-weight-medium align-items-center gap-1">
              {heading.id === 5 ? (
                <IdfTooltip text="Go back to History">
                  <button
                    className="btn btn-sm d-flex align-items-center icon-hover-bg p-0"
                    onClick={() => updateHash('#history')}
                  >
                    <LucideIcon size={20} icon="MoveLeft" />
                  </button>
                </IdfTooltip>
              ) : (
                <LucideIcon icon={heading.icon} size={18} />
              )}
              <TextOverflowTooltip
                text={heading.title?.replace('Title:', '')}
                maxLength={100}
                textStyle="font-weight-medium text-truncate fs-7_1 mb-0"
              />{' '}
              {controls}
            </h3>
          </div>
          {ownerAndDate}
        </div>
      </div>
      <div
        id="chatBox"
        ref={scrollableRef}
        className={`px-7 pt-0 h-100 smooth-scroll flex-1 ${position} overflow-y-auto`}
      >
        {children}
      </div>
      {!hash?.includes('knowledge') ? (
        <Disclaimer style="w-100 text-center fs-7" />
      ) : null}
    </div>
  );
};

export default ThreadLayout;
