/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavBarShortKey } from '../../hooks/useNavBarShortKey';
import useHash from '../../hooks/useHash';
import { Accordion } from 'react-bootstrap';
import LucideIcon from '../commons/LucideIcon';

const NewChat = () => {
  const { updateHash } = useHash();
  const handleNewChat = () => {
    updateHash('chat');
  };
  useNavBarShortKey(['command', 'k'], handleNewChat);

  const NewChatLabel = () => {
    return (
      <div className="d-flex align-items-center w-100 justify-content-between">
        <div className="d-flex align-items-center">
          <LucideIcon icon="Plus" />
          <span className="ml-2 font-size-sm2 font-weight-medium">
            New Chat
          </span>
        </div>
        <div className="d-flex align-items-center gap-1">
          <span
            className="d-inline-flex align-items-center border rounded justify-content-center"
            style={{ height: 18, width: 18 }}
          >
            <LucideIcon icon="Command" size={11} />
          </span>
          <span
            className="border d-flex align-items-center justify-content-center rounded fs-9"
            style={{ height: 18, width: 18 }}
          >
            K
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <ul className="mb-0 px-3 pt-3 list-unstyled d-flex justify-content-between align-items-center">
        <li className="reporting-filter-wrapper accordion-wrapper w-100 mb-1">
          <Accordion>
            <Accordion.Toggle
              eventKey="new-chat"
              onClick={handleNewChat}
              className={`bg-white reporting-filter-toggle accordion-toggle nav-link px-3 py-2 border-bottom cursor-pointer font-size-sm2 font-weight-medium text-black text-capitalize`}
            >
              <NewChatLabel />
            </Accordion.Toggle>
          </Accordion>
        </li>
      </ul>
    </>
  );
};

const Knowledge = () => {
  const { updateHash } = useHash();
  const handleItemClick = () => {
    updateHash('#knowledge');
  };
  const NewChatLabel = () => {
    return (
      <div className="d-flex align-items-center w-100 justify-content-between">
        <div className="d-flex align-items-center">
          <LucideIcon icon="SquareLibrary" size={18} />
          <span className="ml-2 font-size-sm2 font-weight-medium">
            Knowledge
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <ul className="mb-0 px-3 pt-2 list-unstyled d-flex justify-content-between align-items-center">
        <li className="reporting-filter-wrapper accordion-wrapper w-100 mb-1">
          <Accordion>
            <Accordion.Toggle
              eventKey="knowledge"
              onClick={handleItemClick}
              className={`bg-white reporting-filter-toggle accordion-toggle nav-link px-3 py-2 border-bottom cursor-pointer font-size-sm2 font-weight-medium text-black text-capitalize`}
            >
              <NewChatLabel />
            </Accordion.Toggle>
          </Accordion>
        </li>
      </ul>
    </>
  );
};

const SideBar = ({ children, isIdaKnowledgeAllowed = false }) => {
  return (
    <>
      <NewChat />
      {isIdaKnowledgeAllowed && <Knowledge />}
      {children}
    </>
  );
};

export default SideBar;
