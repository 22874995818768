import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { paginationDefault } from '../../../../utils/constants';
import TopLessons from '../TopLessons';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import Pagination from '../../../../components/Pagination';
import ReportSearchOptionDropdown from '../../../../components/commons/ReportSearchOptionDropdown';
import lessonService from '../../../../services/lesson.service';
import categoryService from '../../../../services/category.service';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';

const TrainingTopLessons = ({ dashboard }) => {
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const [showLessons, setShowLessons] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [lessonIds, setLessonIds] = useState([]);
  const [selectedLessonsKeys, setSelectedLessonsKeys] = useState([]);

  const [showCategories, setShowCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [selectedCategoryKeys, setSelectedCategoryKeys] = useState([]);
  const getLessons = async () => {
    const { data } = await lessonService.getLessons({
      page: 1,
      limit: 1000,
      self: false,
    });
    setLessons(data);
  };

  const getLessonsCategories = async () => {
    const { data } = await categoryService.GetCategories(null, {
      page: 1,
      limit: 1000,
    });

    setCategories(data);
  };

  useEffect(() => {
    getLessons();
    getLessonsCategories();
  }, []);

  const toggleModify = () => {
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
  };

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip text={dashboard?.name} placement="bottom">
            <h4 className="card-title mb-0 text-hover-primary text-truncate">
              {dashboard?.name}
            </h4>
          </IdfTooltip>
          <div className="d-flex align-items-center mr-6 pr-9 gap-2">
            <ReportSearchOptionDropdown
              search
              maxLength={22}
              show={showLessons}
              data={lessons}
              setShow={setShowLessons}
              showCheckAll={false}
              label={`Lessons`}
              setQueryFilters={() => {}}
              scrollable="pr-3 text-left"
              handleOptionSelected={() => {}}
              customStyle={{ width: '250px' }}
              customKey={['title', 'id']}
              selectedData={selectedLessonsKeys}
              handleAllSelect={() => {
                setLessonIds([]);
                toggleModify();
              }}
              setSelectedData={setSelectedLessonsKeys}
              handleOnDone={(items) => {
                setLessonIds(items);
                toggleModify();
              }}
              controlledTitle={
                lessons
                  ?.filter((i) => lessonIds?.includes(i.id))
                  .map((i) => i.title)
                  .join(',') || ''
              }
            />

            <ReportSearchOptionDropdown
              search
              maxLength={22}
              show={showCategories}
              data={categories}
              setShow={setShowCategories}
              showCheckAll={false}
              label={`Categories`}
              setQueryFilters={() => {}}
              scrollable="pr-3 text-left"
              handleOptionSelected={() => {}}
              customStyle={{ width: '250px' }}
              customKey={['title', 'id']}
              selectedData={selectedCategoryKeys}
              handleAllSelect={() => {
                setCategoryIds('');
                toggleModify();
              }}
              setSelectedData={setSelectedCategoryKeys}
              handleOnDone={(items) => {
                setCategoryIds(items);
                toggleModify();
              }}
              controlledTitle={
                categories
                  ?.filter((i) => categoryIds?.includes(i.id))
                  .map((i) => i.title)
                  .join(',') || ''
              }
            />
          </div>
        </div>
      </CardHeader>

      <CardBody className="p-0 flex-1 overflow-y-auto">
        <TopLessons
          lessonIds={lessonIds}
          categoryIds={categoryIds}
          paginationPage={paginationPage}
          setPagination={setPagination}
          toggleModify={toggleModify}
        />
      </CardBody>

      <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
        <div className="d-flex align-items-center justify-content-end">
          <Pagination
            showPerPage
            showPages={false}
            paginationInfo={pagination}
            onPageChange={(newPage) => {
              changePaginationPage(newPage, setPaginationPage);
            }}
            onLimitChange={(perPage) => {
              changePaginationLimit(perPage, setPaginationPage);
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default TrainingTopLessons;
